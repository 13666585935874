import React, { FC } from 'react';
import Link from 'next/link';

import { GlobalAnimationsStyle } from '../../../../sites/animations';
import MediaElement from '../../../basic/MediaElement';
import { CustomMedia } from '../../../types/custom-types';
import {
  ColourTheme,
  ContentBlockAnimationMediaBanner,
  FontSize,
  HtmlLink,
  Maybe,
} from '../../../types/middleware-types';
import { isNextMediaList } from '../../../types/type-checkers';
import formatPromoEvent from '../../../utils/DataLayer/formatPromoEvent';
import Accordion from '../../Accordion';
import {
  StyledBackgroundImage,
  StyledBannerContent,
  StyledContentInner,
  StyledCtaList,
  StyledMediaBanner,
  StyledMediaBannerContainer,
} from './MediaBannerStyles';

export interface MediaBannerStructureProps {
  fontSize: FontSize;
  colourTheme: ColourTheme;
  backgroundColour: Maybe<string>;
  title?: Maybe<string>;
  subtitle?: Maybe<string>;
  pageName: Maybe<string> | undefined;
  forwardedRef: (node: HTMLDivElement) => void;
  animate: boolean;
  animationType?: Maybe<ContentBlockAnimationMediaBanner>;
  animationStyle: GlobalAnimationsStyle;
  moduleIndex: number;
  headerHeight: number;
  bannerCtas?: Maybe<Array<HtmlLink>>;
  titleImage?: Maybe<CustomMedia>;
  backgroundImage: CustomMedia;
  setLoading: (type: boolean) => void;
  sahNewHeader?: boolean;
  useReactRoutingLinks: boolean;
}

const MediaBannerStructure: FC<MediaBannerStructureProps> = ({
  backgroundImage,
  subtitle,
  title,
  bannerCtas,
  titleImage,
  pageName,
  colourTheme,
  forwardedRef,
  animationType,
  animate,
  animationStyle,
  moduleIndex,
  fontSize,
  headerHeight,
  backgroundColour = 'transparent',
  setLoading,
  sahNewHeader,
  useReactRoutingLinks,
}) => {
  return (
    <StyledMediaBanner
      $backgroundColour={backgroundColour}
      className="branded-banner"
      data-hookid={`${pageName}MediaBanner`}
      ref={forwardedRef}
    >
      <StyledMediaBannerContainer
        $animationType={animationType}
        $animate={animate}
        $animationStyle={animationStyle()}
        $moduleIndex={moduleIndex}
      >
        <StyledBackgroundImage
          $animationType={animationType}
          $animate={animate}
          $animationStyle={animationStyle()}
          $moduleIndex={moduleIndex}
        >
          <MediaElement {...backgroundImage} imgPriority={moduleIndex === 0} sizes="100vw" />
        </StyledBackgroundImage>
        <StyledBannerContent
          $colourTheme={colourTheme}
          $fontMediaSize={fontSize}
          $headerHeight={headerHeight}
          $sahNewHeader={sahNewHeader}
        >
          <StyledContentInner>
            {titleImage ? (
              <MediaElement {...titleImage} imageType="intrinsic" />
            ) : (
              <div className="custom-size-title">
                <h2>{title}</h2>
              </div>
            )}
            <p className="custom-size-description">{subtitle}</p>
          </StyledContentInner>
          {titleImage || title ? (
            <Accordion titleImage={titleImage} title={title || ''} text={subtitle} />
          ) : null}
          <StyledCtaList $ctaNumber={bannerCtas?.length} $sahNewHeader={sahNewHeader}>
            {useReactRoutingLinks && bannerCtas?.map((cta, index) => (
              <li key={cta.text}>
                <Link href={cta.url} passHref legacyBehavior>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  <a
                    className="custom-size-link"
                    {...(cta.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
                    onClick={() => {
                      formatPromoEvent(
                        title,
                        index + 1,
                        cta.url,
                        isNextMediaList(titleImage?.mediaList)
                          ? titleImage?.mediaList?.desktop?.src
                          : '',
                        `cta${index + 1}`,
                        'click',
                      );
                      setLoading(true);
                    }}
                  >
                    {cta.text}
                  </a>
                </Link>
              </li>
            ))}
            {!useReactRoutingLinks && bannerCtas?.map((cta, index) => (
              <li key={cta.text}>
                <a
                  className="custom-size-link"
                  href={cta.url}
                  {...(cta.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
                  onClick={() => {
                    formatPromoEvent(
                      title,
                      index + 1,
                      cta.url,
                      isNextMediaList(titleImage?.mediaList)
                        ? titleImage?.mediaList?.desktop?.src
                        : '',
                      `cta${index + 1}`,
                      'click',
                    );
                    setLoading(true);
                  }}
                >
                  {cta.text}
                </a>
              </li>
            ))}
          </StyledCtaList>
        </StyledBannerContent>
      </StyledMediaBannerContainer>
    </StyledMediaBanner>
  );
};

export default MediaBannerStructure;
