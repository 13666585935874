import { css } from 'styled-components';

interface fontMediaSizeInterface {
  $fontMediaSize?: string;
}

export const CustomSizeSubtitle = css<fontMediaSizeInterface>`
  font-family: ${({ theme }) => theme.blockFonts.subtitle};
  font-size: ${({ $fontMediaSize, theme }) => ($fontMediaSize === 'SMALL' && theme.blockFontSizes.subtitleS) ||
    ($fontMediaSize === 'LARGE' && theme.blockFontSizes.subtitleL) ||
    theme.blockFontSizes.subtitleM} !important;
  line-height: calc(${({ $fontMediaSize, theme }) => ($fontMediaSize === 'SMALL' && theme.blockFontSizes.subtitleS) ||
    ($fontMediaSize === 'LARGE' && theme.blockFontSizes.subtitleL) ||
    theme.blockFontSizes.subtitleM} + 0.2rem) !important;
`;

export const CustomSizeDescription = css<fontMediaSizeInterface>`
  font-size: ${({ $fontMediaSize, theme }) => ($fontMediaSize === 'SMALL' && theme.blockFontSizes.descriptionCtaS) ||
    ($fontMediaSize === 'LARGE' && theme.blockFontSizes.descriptionCtaL) ||
    theme.blockFontSizes.descriptionCtaM} !important;
  line-height: calc(${({ $fontMediaSize, theme }) => ($fontMediaSize === 'SMALL' && theme.blockFontSizes.descriptionCtaS) ||
    ($fontMediaSize === 'LARGE' && theme.blockFontSizes.descriptionCtaL) ||
    theme.blockFontSizes.descriptionCtaM} + 0.2rem) !important;
`

export const MediaFontSize = css<fontMediaSizeInterface>`
  .custom-size-title {
    h1,
    h2 {
      font-weight: normal;
      letter-spacing: ${({$fontMediaSize, theme}) => $fontMediaSize === 'LARGE' && theme.blockLetterSpacing.headingL};
      font-family: ${({ $fontMediaSize, theme }) => ($fontMediaSize === 'SMALL' && theme.blockFonts.titleS) ||
        ($fontMediaSize === 'LARGE' && theme.blockFonts.titleL) || 
        theme.blockFonts.titleM};
      font-size: ${({ $fontMediaSize, theme }) => ($fontMediaSize === 'SMALL' && theme.blockFontSizes.headingS) ||
        ($fontMediaSize === 'LARGE' && theme.blockFontSizes.headingLMobile) ||
        theme.blockFontSizes.headingM} !important;
      line-height: calc(${({ $fontMediaSize, theme }) =>
          ($fontMediaSize === 'SMALL' && theme.blockFontSizes.headingS) ||
          ($fontMediaSize === 'LARGE' && theme.blockFontSizes.headingLMobile) ||
          theme.blockFontSizes.headingM} + 0.2rem) !important;

      @media ${({ theme }) => theme.devices.large} {
        font-size: ${({ $fontMediaSize, theme }) => ($fontMediaSize === 'SMALL' && theme.blockFontSizes.headingS) ||
          ($fontMediaSize === 'LARGE' && theme.blockFontSizes.headingL) ||
          theme.blockFontSizes.headingM} !important;
        line-height: calc(${({ $fontMediaSize, theme }) =>
            ($fontMediaSize === 'SMALL' && theme.blockFontSizes.headingS) ||
            ($fontMediaSize === 'LARGE' && theme.blockFontSizes.headingL) ||
            theme.blockFontSizes.headingM} + 0.2rem) !important;
      }
    }
  }

  .custom-size-subtitle {
    ${CustomSizeSubtitle}
  }

  .custom-size-media-description,
  .custom-size-description,
  .custom-size-link {
    ${CustomSizeDescription}
  }
`;
