export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A valid birthday in the format DD-MM. Days are limited to 28, 29, 30, 31 depending on the month. */
  Birthday: any;
  /** A string made up of alphanumeric characters and underscores. */
  CategoryPath: any;
  /** A string using date format YYYY-MM-DD, example: 2021-01-01 */
  Date: any;
  /** A valid email address */
  EmailAddress: any;
  /** A string with up to 21 digits representing a gift card number. */
  GiftCardNumber: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](https://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** A field whose value is a valid decimal degrees latitude number (53.471) */
  Latitude: any;
  /** A string made up of 10 digits */
  LineNumber: any;
  /** A field whose value is a valid decimal degrees longitude number (53.471) */
  Longitude: any;
  /** A non-empty string with whitespaces trimmed */
  NonEmptyString: any;
  /** A valid password must be at least 8 characters long and contain at least 3 of the following character classes: lowercase letters, uppercase letters, digits, special characters. */
  Password: any;
  /** A string made up of 2 digits. */
  ProductGenderCode: any;
  /** An integer with a minimum value of 1 */
  ProductListCurrentPage: any;
  /** An integer with a maximum value of 36 */
  ProductListPageSize: any;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: any;
};


export type AddProductToBagResponse = {
  __typename?: 'AddProductToBagResponse';
  bag?: Maybe<Bag>;
  error?: Maybe<Scalars['String']>;
};

export type AddProductToCartInput = {
  lineNumber?: Maybe<Scalars['LineNumber']>;
  sku: Scalars['String'];
  quantity: Scalars['Int'];
};

export type AddProductToCartResponse = {
  __typename?: 'AddProductToCartResponse';
  cart?: Maybe<Cart>;
};

export type AddressDetails = {
  __typename?: 'AddressDetails';
  id: Scalars['String'];
  department: Scalars['String'];
  company: Scalars['String'];
  street: Scalars['String'];
  city: Scalars['String'];
  line1: Scalars['String'];
  line2: Scalars['String'];
  line3: Scalars['String'];
  line4: Scalars['String'];
  line5: Scalars['String'];
  province: Scalars['String'];
  provinceName: Scalars['String'];
  provinceCode: Scalars['String'];
  postalCode: Scalars['String'];
  countryName: Scalars['String'];
  countryIso2: Scalars['String'];
  poBoxNumber: Scalars['String'];
  label: Scalars['String'];
  type: Scalars['String'];
};

export enum AddressFilter {
  Defaults = 'DEFAULTS',
  DefaultBilling = 'DEFAULT_BILLING',
  DefaultShipping = 'DEFAULT_SHIPPING'
}

export type AddressGeocodeDetails = {
  __typename?: 'AddressGeocodeDetails';
  streetNumber: Scalars['String'];
  streetName: Scalars['String'];
  city: Scalars['String'];
  province: Scalars['String'];
  postalCode: Scalars['String'];
  countryCode: Scalars['String'];
  distance: Scalars['Float'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type AddressLookupAddresses = {
  __typename?: 'AddressLookupAddresses';
  id: Scalars['String'];
  type: Scalars['String'];
  description: Scalars['String'];
  text: Scalars['String'];
  highlight?: Maybe<AddressLookupHighlight>;
};

export type AddressLookupByGeocodeResponse = {
  __typename?: 'AddressLookupByGeocodeResponse';
  error?: Maybe<AddressLookupError>;
  address?: Maybe<AddressGeocodeDetails>;
};

export type AddressLookupBySearchResponse = {
  __typename?: 'AddressLookupBySearchResponse';
  error?: Maybe<AddressLookupError>;
  items: Array<Maybe<AddressLookupAddresses>>;
};

export type AddressLookupError = {
  __typename?: 'AddressLookupError';
  cause: Scalars['String'];
  description: Scalars['String'];
  error: Scalars['String'];
  resolution: Scalars['String'];
};

export type AddressLookupHighlight = {
  __typename?: 'AddressLookupHighlight';
  text: Array<Maybe<Scalars['String']>>;
  description: Array<Maybe<Scalars['String']>>;
};

export type AddressLookupRetrievalResponse = {
  __typename?: 'AddressLookupRetrievalResponse';
  error?: Maybe<AddressLookupError>;
  address?: Maybe<AddressDetails>;
};

export type AddressRegionInput = {
  regionId: Scalars['Int'];
  regionCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type AdvertListItem = {
  __typename?: 'AdvertListItem';
  id: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
  openInNewTab: Scalars['Boolean'];
  cellSpan: Scalars['Int'];
  image?: Maybe<ImgDetails>;
  wistiaVideoId?: Maybe<Scalars['String']>;
};

export type AdyenInput = {
  ccType?: Maybe<Scalars['String']>;
  brandCode?: Maybe<Scalars['String']>;
  /** JSON.stringify() the state.data object that you get from the Web Component. */
  stateData: Scalars['JSONObject'];
};

export type AdyenPaymentMethodIcon = {
  __typename?: 'AdyenPaymentMethodIcon';
  /** URL of the icon. */
  url?: Maybe<Scalars['String']>;
  /** Width of the icon in pixels. */
  width?: Maybe<Scalars['Int']>;
  /** Height of the icon in pixels. */
  height?: Maybe<Scalars['Int']>;
};

export type AdyenPaymentMethods = {
  __typename?: 'AdyenPaymentMethods';
  /** API response from Adyen with payment methods. */
  paymentMethodsResponse?: Maybe<AdyenPaymentMethodsResponse>;
  /** Payment method's additional details. */
  paymentMethodsExtraDetails?: Maybe<Array<Maybe<AdyenPaymentMethodsExtraDetails>>>;
};

export type AdyenPaymentMethodsArray = {
  __typename?: 'AdyenPaymentMethodsArray';
  /** The displayable name of this payment method. */
  name?: Maybe<Scalars['String']>;
  /** The unique payment method code. */
  type?: Maybe<Scalars['String']>;
  /** Brand for the selected gift card. For example: plastix, hmclub. */
  brand?: Maybe<Scalars['String']>;
  /** List of possible brands. For example: visa, mc. */
  brands?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The configuration of the payment method. */
  configuration?: Maybe<AdyenPaymentMethodsConfiguration>;
  /**
   * All input details to be provided to complete the payment with this payment method.
   * @deprecated This field will be removed in the next major version following the deprecation in the Checkout API.
   */
  details?: Maybe<Array<Maybe<AdyenPaymentMethodsDetails>>>;
  /** Payment method issuer list. */
  issuers?: Maybe<Array<Maybe<AdyenPaymentMethodsIssuers>>>;
};

export type AdyenPaymentMethodsConfiguration = {
  __typename?: 'AdyenPaymentMethodsConfiguration';
  /** ID of the merchant. */
  merchantId?: Maybe<Scalars['String']>;
  /** Name of the merchant. */
  merchantName?: Maybe<Scalars['String']>;
  /** Name of the merchant for Google Pay. */
  gatewayMerchantId?: Maybe<Scalars['String']>;
};

export type AdyenPaymentMethodsDetails = {
  __typename?: 'AdyenPaymentMethodsDetails';
  /** The value to provide in the result. */
  key?: Maybe<Scalars['String']>;
  /** The type of the required input. */
  type?: Maybe<Scalars['String']>;
  /** The items to choose from in case that the payment method includes a selection list. */
  items?: Maybe<Array<Maybe<AdyenPaymentMethodsDetailsItems>>>;
  /** True if this input is optional. */
  optional?: Maybe<Scalars['String']>;
  /** The value can be pre-filled, if available. */
  value?: Maybe<Scalars['String']>;
};

export type AdyenPaymentMethodsDetailsItems = {
  __typename?: 'AdyenPaymentMethodsDetailsItems';
  /** The value to provide in the result. */
  id?: Maybe<Scalars['String']>;
  /** The display name. */
  name?: Maybe<Scalars['String']>;
};

export type AdyenPaymentMethodsExtraDetails = {
  __typename?: 'AdyenPaymentMethodsExtraDetails';
  /** The unique payment method code. */
  type?: Maybe<Scalars['String']>;
  /** Icon for the payment method. */
  icon?: Maybe<AdyenPaymentMethodIcon>;
  /** True if the payment method is Open Invoice. */
  isOpenInvoice?: Maybe<Scalars['Boolean']>;
  /** Extra configuration settings. */
  configuration?: Maybe<AdyenPaymentMethodsExtraDetailsConfiguration>;
};

export type AdyenPaymentMethodsExtraDetailsConfiguration = {
  __typename?: 'AdyenPaymentMethodsExtraDetailsConfiguration';
  /** Current order amount in minor units. */
  amount?: Maybe<Money>;
};

export type AdyenPaymentMethodsIssuers = {
  __typename?: 'AdyenPaymentMethodsIssuers';
  /** Issuer ID. */
  id?: Maybe<Scalars['String']>;
  /** Issuer name. */
  name?: Maybe<Scalars['String']>;
};

export type AdyenPaymentMethodsResponse = {
  __typename?: 'AdyenPaymentMethodsResponse';
  paymentMethods?: Maybe<Array<Maybe<AdyenPaymentMethodsArray>>>;
  storedPaymentMethods?: Maybe<Array<Maybe<AdyenStoredPaymentMethodsArray>>>;
};

export type AdyenStoredPaymentMethodsArray = {
  __typename?: 'AdyenStoredPaymentMethodsArray';
  /** A unique identifier of this stored payment method. */
  id?: Maybe<Scalars['String']>;
  /** The brand of the card. */
  brand?: Maybe<Scalars['String']>;
  /** The month the card expires. */
  expiryMonth?: Maybe<Scalars['String']>;
  /** The year the card expires. */
  expiryYear?: Maybe<Scalars['String']>;
  /** The unique payment method code. */
  holderName?: Maybe<Scalars['String']>;
  /** The last four digits of the PAN. */
  lastFour?: Maybe<Scalars['String']>;
  /** The display name of the stored payment method. */
  name?: Maybe<Scalars['String']>;
  /** The IBAN of the bank account. */
  iban?: Maybe<Scalars['String']>;
  /** The name of the bank account holder. */
  ownerName?: Maybe<Scalars['String']>;
  /** The shopper’s email address. */
  shopperEmail?: Maybe<Scalars['String']>;
  /** Returned in the response if you are not tokenizing with Adyen and are using the Merchant-initiated transactions (MIT) framework from Mastercard or Visa. This contains either the Mastercard Trace ID or the Visa Transaction ID. */
  networkTxReference?: Maybe<Scalars['String']>;
  /** The supported shopper interactions for this stored payment method. */
  supportedShopperInteractions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The type of payment method. */
  type?: Maybe<Scalars['String']>;
};

export type AmazonCheckoutSessionConfig = {
  __typename?: 'AmazonCheckoutSessionConfig';
  payloadJSON: Scalars['String'];
  signature: Scalars['String'];
};

export type AmazonPayButton = {
  __typename?: 'AmazonPayButton';
  merchantId: Scalars['String'];
  publicKeyId: Scalars['String'];
  ledgerCurrency: Scalars['String'];
  checkoutLanguage: Scalars['String'];
  productType: Scalars['String'];
  placement: Scalars['String'];
  buttonColor: Scalars['String'];
  createCheckoutSessionConfig: AmazonCheckoutSessionConfig;
};

export type AppStores = {
  __typename?: 'AppStores';
  accounts: Array<ThirdPartyAccount>;
};

export type AppliedCoupon = {
  __typename?: 'AppliedCoupon';
  code: Scalars['String'];
};

export type AppliedGiftCard = {
  __typename?: 'AppliedGiftCard';
  code: Scalars['String'];
  appliedBalance: Scalars['String'];
};

export type ApplyCouponToCartInput = {
  couponCode: Scalars['String'];
};

export type ApplyCouponToCartResponse = {
  __typename?: 'ApplyCouponToCartResponse';
  cart?: Maybe<Cart>;
};

export type ApplyGiftCardToCartResponse = {
  __typename?: 'ApplyGiftCardToCartResponse';
  cart?: Maybe<Cart>;
};

export type ApplyGiftCardToCartsInput = {
  giftCardCode: Scalars['String'];
  giftCardPin: Scalars['String'];
  amount: Scalars['Float'];
};

export enum ArrowPosition {
  Side = 'SIDE',
  Bottom = 'BOTTOM'
}

export type AssignForterTokenToCartInput = {
  forterToken: Scalars['String'];
};

export type AssignForterTokenToCartResponse = {
  __typename?: 'AssignForterTokenToCartResponse';
  success: Scalars['Boolean'];
};

export type AvailableShippingMethods = {
  __typename?: 'AvailableShippingMethods';
  carrierCode: Scalars['String'];
  carrierTitle?: Maybe<Scalars['String']>;
  methodCode?: Maybe<Scalars['String']>;
  methodTitle?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  amount: Money;
  baseAmount?: Maybe<Money>;
  priceExclTax: Money;
  priceInclTax: Money;
  available: Scalars['Boolean'];
  extensionAttributes?: Maybe<ShippingMethodExtensionAttributes>;
};

export type Bag = {
  __typename?: 'Bag';
  _id: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  /** The total number of items in the cart, including each item's quantity. */
  itemCount: Scalars['Int'];
  /** Items to display in the minibag, most recently added will be first. */
  items: Array<CartItem>;
  /** Number of items remaining in the cart that we aren't displaying. */
  remainingItems?: Maybe<Scalars['Int']>;
  subTotal: Scalars['String'];
  subTotalFormatted: Scalars['String'];
};

export type BillingAddressInput = {
  /** An ID from the customer's address book that uniquely identifies the address to be used for billing. */
  customerAddressId?: Maybe<Scalars['Int']>;
  address?: Maybe<CartAddressInput>;
  /** Indicates whether to set the shipping address to be the same as this billing address. */
  useForShipping?: Maybe<Scalars['Boolean']>;
  /** Indicates whether to set the billing address to be the same as the existing shipping address on the cart. */
  sameAsShipping?: Maybe<Scalars['Boolean']>;
};


export type BlockMediaBasicItem = {
  __typename?: 'BlockMediaBasicItem';
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  callToAction?: Maybe<HtmlLink>;
  callToActionLinkWrapsItem: Scalars['Boolean'];
  textAlignment?: Maybe<TextAlignment>;
  colourTheme: ColourTheme;
  fontSize?: Maybe<FontSize>;
  description?: Maybe<Scalars['String']>;
  itemLogo?: Maybe<ImageV2>;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentCustomPositionDesktop: ContentCustomPosition;
  contentDefaultPositionMobile: MultipleDefaultContentPositions;
  contentCustomPositionMobile: ContentCustomPosition;
  marginDesktop: Margin;
  marginMobile: Margin;
  hideOnMobile?: Maybe<Scalars['Boolean']>;
  media: Media;
};

export type BlockMediaCarouselItem = {
  __typename?: 'BlockMediaCarouselItem';
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  callToAction?: Maybe<HtmlLink>;
  callToActionLinkWrapsItem: Scalars['Boolean'];
  colourTheme: ColourTheme;
  textAlignment?: Maybe<TextAlignment>;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentCustomPositionDesktop: ContentCustomPosition;
  contentDefaultPositionMobile: MultipleDefaultContentPositions;
  contentCustomPositionMobile: ContentCustomPosition;
  media?: Maybe<Media>;
};

export type BlockMediaCarouselSimpleMobileOnlyItem = {
  __typename?: 'BlockMediaCarouselSimpleMobileOnlyItem';
  callToAction: HtmlLink;
  image: ImageV2;
};

export type BlockShopBySizeItem = {
  __typename?: 'BlockShopBySizeItem';
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  /** @deprecated Use background, text and link colour fields on the media block; expected deletion date: "01/02/2024" */
  colourTheme: ColourTheme;
  linkGroups: Array<BlockShopBySizeLinks>;
};

export type BlockShopBySizeLinks = {
  __typename?: 'BlockShopBySizeLinks';
  title: Scalars['String'];
  links: Array<HtmlLink>;
};

export type BrandIndex = {
  __typename?: 'BrandIndex';
  index: Array<SubMenuIndexLink>;
  brandListLinks: Array<BrandLetterLinkList>;
};

export type BrandLetterLinkList = {
  __typename?: 'BrandLetterLinkList';
  letter?: Maybe<Scalars['String']>;
  links?: Maybe<Array<HtmlLink>>;
};

export type CmsAccountPage = {
  __typename?: 'CMSAccountPage';
  id: Scalars['String'];
  title: Scalars['String'];
  topBannerImage: ImageV2;
  bottomBannerImage: ImageV2;
};

export type CmsBlocksPage = {
  __typename?: 'CMSBlocksPage';
  id: Scalars['String'];
  metadata?: Maybe<Metadata>;
  title?: Maybe<Scalars['String']>;
  background?: Maybe<ImageV2>;
  logo?: Maybe<ImageV2>;
  headerColourTheme: HeaderColourTheme;
  blocks?: Maybe<Array<ContentBlock>>;
};

export type CmsBrandIndexPage = {
  __typename?: 'CMSBrandIndexPage';
  id: Scalars['String'];
  metadata?: Maybe<Metadata>;
  banner: ContentBlockMediaBanner;
  title: Scalars['String'];
  subtitle: Scalars['String'];
  brandIndex: BrandIndex;
};

export type CmsFlashSalePage = {
  __typename?: 'CMSFlashSalePage';
  id: Scalars['String'];
  metadata?: Maybe<Metadata>;
  title: Scalars['String'];
  subtitle: Scalars['String'];
  activePromos?: Maybe<Array<FlashSalePromo>>;
  inactiveSubtitle: Scalars['String'];
  expiredPromos?: Maybe<Array<FlashSalePromo>>;
};

export type CmsOutOfStockPage = {
  __typename?: 'CMSOutOfStockPage';
  id: Scalars['String'];
  metadata?: Maybe<Metadata>;
  colourTheme: ColourTheme;
  title: Scalars['String'];
  subtitle: Scalars['String'];
  backgroundImage: ImageV2;
  menu: Array<OutOfStockMenu>;
};

export type CmsPage = CmsBlocksPage | CmsFlashSalePage | CmsAccountPage | CmsBrandIndexPage | CmsStoriesCampaignPage | CmsStoriesListingPage | CmsStoriesFilmPage | CmsStoriesTrendPage | CmsTrackMyOrderPage | CmsOutOfStockPage | CmsStoriesBlocksPage;

export type CmsProductPage = {
  __typename?: 'CMSProductPage';
  id: Scalars['String'];
  deliveryOptions: Array<DeliveryOption>;
  variableShipping: Array<VariableShipping>;
  sizeGuideInstructions: Scalars['String'];
  SizeGuideMoreInformationInstructions: Scalars['String'];
  sizeGuideMoreInformationEmail: Scalars['String'];
  sizeGuideHtml?: Maybe<Scalars['String']>;
  celebrityStyleCarousel?: Maybe<ContentBlockCelebrityStyleCarousel>;
};

export type CmsStoriesBlocksPage = {
  __typename?: 'CMSStoriesBlocksPage';
  id: Scalars['String'];
  metadata?: Maybe<Metadata>;
  background?: Maybe<ImageV2>;
  logo?: Maybe<ImageV2>;
  headerColourTheme: HeaderColourTheme;
  blocks?: Maybe<Array<ContentBlock>>;
  relatedStories?: Maybe<Array<StoriesGeneralListItem>>;
};

export type CmsStoriesCampaignPage = {
  __typename?: 'CMSStoriesCampaignPage';
  id: Scalars['String'];
  metadata?: Maybe<Metadata>;
  title: Scalars['String'];
  widgetRawHtml?: Maybe<Scalars['String']>;
  mainHtml: Scalars['String'];
  callToAction?: Maybe<HtmlLink>;
  galleryImages: Array<ImageV2>;
  recentStories: RecentStories;
};

export type CmsStoriesFilmPage = {
  __typename?: 'CMSStoriesFilmPage';
  id: Scalars['String'];
  metadata?: Maybe<Metadata>;
  title: Scalars['String'];
  film?: Maybe<Video>;
  widgetRawHtml?: Maybe<Scalars['String']>;
  introHtml?: Maybe<Scalars['String']>;
  productCarousel?: Maybe<ContentBlockProductCarousel>;
  mainHtml: Scalars['String'];
  callToAction?: Maybe<HtmlLink>;
  recentStories: RecentStories;
};

export type CmsStoriesListItem = StoriesGeneralListItem | CelebrityStyleCarouselItem;

export type CmsStoriesListingPage = {
  __typename?: 'CMSStoriesListingPage';
  id: Scalars['String'];
  headerColourTheme?: Maybe<HeaderColourTheme>;
  banner?: Maybe<ContentBlockMediaBasic>;
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  metadata?: Maybe<Metadata>;
  items: Array<Maybe<CmsStoriesListItem>>;
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
};

export type CmsStoriesTrendPage = {
  __typename?: 'CMSStoriesTrendPage';
  id: Scalars['String'];
  metadata?: Maybe<Metadata>;
  title: Scalars['String'];
  widgetRawHtml?: Maybe<Scalars['String']>;
  introHtml?: Maybe<Scalars['String']>;
  productCarousel?: Maybe<ContentBlockProductCarousel>;
  mainHtml: Scalars['String'];
  callToAction?: Maybe<HtmlLink>;
  recentStories: RecentStories;
};

export type CmsTrackMyOrderPage = {
  __typename?: 'CMSTrackMyOrderPage';
  id: Scalars['String'];
  metadata?: Maybe<Metadata>;
  title: Scalars['String'];
  html: Scalars['String'];
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export enum CallToActionArrangement {
  Horizontalrow = 'HORIZONTALROW',
  Columns = 'COLUMNS'
}

export enum CallToActionStyle {
  Textlink = 'TEXTLINK',
  Button = 'BUTTON'
}

export type Campaign = {
  id: Scalars['String'];
  sourceId: Scalars['String'];
  style: Scalars['String'];
  slogan: Scalars['String'];
  title: Scalars['String'];
};

export type CampaignContent = {
  __typename?: 'CampaignContent';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quickLinks?: Maybe<LinkList>;
};

export enum CarouselMediaItemStyle {
  Separate = 'SEPARATE',
  Sticky = 'STICKY'
}

export enum CarouselScrollStyleOnDesktop {
  Carousel = 'CAROUSEL',
  Scrollbar = 'SCROLLBAR'
}

export type Cart = {
  __typename?: 'Cart';
  id: Scalars['String'];
  isCvsOrder: Scalars['Boolean'];
  /** The total number of items in the cart, including each item's quantity. */
  itemCount: Scalars['Int'];
  /** Items to display in the cart, most recently added will be first. */
  items: Array<CartItem>;
  /** @deprecated please use prices instead; expected deletion date: "01/02/2025" */
  subTotal: Scalars['String'];
  /** @deprecated please use prices instead; expected deletion date: "01/02/2025" */
  subTotalFormatted: Scalars['String'];
  /** @deprecated please use prices instead; expected deletion date: "01/02/2025" */
  grandTotal: Scalars['String'];
  /** @deprecated please use prices instead; expected deletion date: "01/02/2025" */
  grandTotalFormatted: Scalars['String'];
  prices?: Maybe<CartPrices>;
  appliedCoupons: Array<AppliedCoupon>;
  appliedGiftCards: Array<AppliedGiftCard>;
  shippingAddresses?: Maybe<Array<Maybe<ShippingCartAddress>>>;
};

export type CartAddressInput = {
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  street: Array<Maybe<Scalars['String']>>;
  city?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  countryCodeISO2: Scalars['String'];
  telephone: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['Int']>;
  saveInAddressBook?: Maybe<Scalars['Boolean']>;
  /** Different validation rules need to be enforced in code if not a click and collect address */
  isAClickAndCollectAddress: Scalars['Boolean'];
};

export type CartItem = {
  __typename?: 'CartItem';
  id: Scalars['String'];
  product: CartItemProduct;
  quantity: Scalars['Int'];
  prices?: Maybe<CartItemPrices>;
  configurableOptions?: Maybe<Array<ConfigurableOption>>;
  configuredVariant?: Maybe<ConfiguredVariant>;
  availableSizeOptions: Array<SizeOptions>;
  isCvsItem: Scalars['Boolean'];
  cvsItemMessage?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<CartItemError>>>;
  /** @deprecated Unfortunately rowTotal can no longer be provided; expected deletion date: "01/02/2024" */
  rowTotal?: Maybe<Scalars['String']>;
};

export type CartItemError = {
  __typename?: 'CartItemError';
  code: CartItemErrorType;
  message: Scalars['String'];
};

export enum CartItemErrorType {
  Undefined = 'UNDEFINED',
  ItemQty = 'ITEM_QTY',
  ItemIncrements = 'ITEM_INCREMENTS'
}

export type CartItemPrices = {
  __typename?: 'CartItemPrices';
  discounts?: Maybe<Array<Discount>>;
  fixedProductTaxes?: Maybe<Array<FixedProductTax>>;
  price: Money;
  priceIncludingTax: Money;
  wasPrice?: Maybe<Money>;
  rowWasTotal?: Maybe<Money>;
  rowTotal: Money;
  rowTotalIncludingTax: Money;
  totalItemDiscount?: Maybe<Money>;
};

export type CartItemProduct = {
  __typename?: 'CartItemProduct';
  id: Scalars['String'];
  name: Scalars['String'];
  urlKey: Scalars['String'];
  url: Scalars['String'];
  /** The product's line number, unique to the product's colour. */
  lineNumber: Scalars['LineNumber'];
  /** The product's stock keeping unit, unique to the product's colour and size. */
  sku: Scalars['String'];
  brand: Scalars['String'];
  brandCode: Scalars['String'];
  productGenderCode: Scalars['ProductGenderCode'];
  colour?: Maybe<Scalars['String']>;
  sizeLabel: Scalars['String'];
  /** @deprecated Please use sizeLabel instead; expected deletion date: "01/02/2025" */
  sizeLabels: SizeLabels;
  productImage?: Maybe<ProductImageInfo>;
  newIn: Scalars['Boolean'];
  sale: Scalars['Boolean'];
  offer: Scalars['Boolean'];
  exclusive: Scalars['Boolean'];
  clearance: Scalars['Boolean'];
  priceDrop: Scalars['Boolean'];
  price: ProductPrice;
  /** @deprecated Please use productGenderCode instead; expected deletion date: "01/02/2024" */
  genderCode?: Maybe<Scalars['String']>;
  /** @deprecated Please use sizeLabels instead; expected deletion date: "01/02/2024" */
  size?: Maybe<Scalars['String']>;
  /** @deprecated Please use productImage instead; expected deletion date: "01/02/2024" */
  image?: Maybe<Image>;
};

export type CartItemUpdateInput = {
  cartItemUid: Scalars['String'];
  quantity: Scalars['Float'];
  cartItemId?: Maybe<Scalars['String']>;
  giftMessage?: Maybe<Scalars['String']>;
  giftWrappingId?: Maybe<Scalars['String']>;
  customizableOptions?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
};

export type CartPrices = {
  __typename?: 'CartPrices';
  grandTotal: Money;
  subtotalIncludingTax: Money;
  subtotalExcludingTax: Money;
  subtotalWithDiscountExcludingTax: Money;
  appliedTaxes?: Maybe<Array<Maybe<CartTaxItem>>>;
  discounts?: Maybe<Array<Maybe<Discount>>>;
};

export type CartShippingMethod = {
  __typename?: 'CartShippingMethod';
  optionId: CartShippingMethodOptions;
  label: Scalars['String'];
  price: Scalars['String'];
};

export enum CartShippingMethodOptions {
  ToAddress = 'toAddress',
  ToCollectionPoint = 'toCollectionPoint'
}

export type CartTaxItem = {
  __typename?: 'CartTaxItem';
  amount: Money;
  label: Scalars['String'];
};

export type Category = {
  __typename?: 'Category';
  sourceId: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Please use banner instead; expected deletion date: "01/02/2024" */
  image?: Maybe<Image>;
  banner?: Maybe<CategoryBanner>;
  shopBySize?: Maybe<SizeLinkList>;
  quickLinks?: Maybe<LinkList>;
  items: Array<ListItem>;
  pagination: CategoryPagination;
  filters: Array<CategoryFilter>;
  sortBy: Array<CategorySortByOption>;
  activeFilters: Array<CategoryActiveFilter>;
  breadcrumbs: Array<CategoryBreadcrumb>;
  recommendations: Array<ProductRecommendations>;
  footerAdvert?: Maybe<CategoryAdvert>;
  categoryCarousel?: Maybe<ImageLinkCampaign>;
  shouldIndex: Scalars['Boolean'];
  canonicalUrl?: Maybe<Scalars['String']>;
};

export type CategoryActiveFilter = {
  __typename?: 'CategoryActiveFilter';
  id: Scalars['String'];
  optionId: Scalars['String'];
  label: Scalars['String'];
  filterId: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  isRootCategory: Scalars['Boolean'];
  shouldFollow: Scalars['Boolean'];
};

export type CategoryAdvert = {
  __typename?: 'CategoryAdvert';
  campaignId: Scalars['String'];
  image: ImageV2;
  link?: Maybe<HtmlLink>;
};

export type CategoryBanner = {
  __typename?: 'CategoryBanner';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  callToActions: Array<Cta>;
  backgroundImage: ImageV2;
  titleImage?: Maybe<ImageV2>;
  colourTheme: ColourTheme;
};

export type CategoryBreadcrumb = {
  __typename?: 'CategoryBreadcrumb';
  id: Scalars['String'];
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type CategoryFilter = {
  __typename?: 'CategoryFilter';
  filterId: Scalars['String'];
  label: Scalars['String'];
  type: Scalars['String'];
  expanded: Scalars['Boolean'];
  options: Array<CategoryFilterOption>;
  facetId: Scalars['String'];
};

export type CategoryFilterOption = {
  __typename?: 'CategoryFilterOption';
  id: Scalars['String'];
  optionId: Scalars['String'];
  label: Scalars['String'];
  selected: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  itemCount: Scalars['Int'];
  shouldFollow: Scalars['Boolean'];
};

export type CategoryLinks = {
  __typename?: 'CategoryLinks';
  title: Scalars['String'];
  identifier: Scalars['String'];
  categoryUrl: Scalars['String'];
  links?: Maybe<Array<Link>>;
};

export type CategoryPagination = {
  __typename?: 'CategoryPagination';
  /** @deprecated please use hasPreviousPage instead; expected deletion date: "01/02/2024" */
  prevEnabled: Scalars['Boolean'];
  /** @deprecated please use previousPage instead; expected deletion date: "01/02/2024" */
  prevUrl?: Maybe<Scalars['String']>;
  /** @deprecated please use hasNextPage instead; expected deletion date: "01/02/2024" */
  nextEnabled: Scalars['Boolean'];
  /** @deprecated please use nextPage instead; expected deletion date: "01/02/2024" */
  nextUrl?: Maybe<Scalars['String']>;
  hasPreviousPage: Scalars['Boolean'];
  previousPage?: Maybe<CategoryPaginationPage>;
  hasNextPage: Scalars['Boolean'];
  nextPage?: Maybe<CategoryPaginationPage>;
  pages: Array<CategoryPaginationPage>;
  currentPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalItems: Scalars['Int'];
  ads: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type CategoryPaginationPage = {
  __typename?: 'CategoryPaginationPage';
  pageNumber?: Maybe<Scalars['Int']>;
  label: Scalars['String'];
  selected: Scalars['Boolean'];
  shouldFollow: Scalars['Boolean'];
};


export type CategoryRoute = Route & {
  __typename?: 'CategoryRoute';
  requestUrl: Scalars['URL'];
  /** CategoryPath used to identify the category. */
  categoryPath: Scalars['CategoryPath'];
};

export type CategorySortByOption = {
  __typename?: 'CategorySortByOption';
  id: Scalars['String'];
  label: Scalars['String'];
  selected: Scalars['Boolean'];
  shouldFollow: Scalars['Boolean'];
};

export type CelebrityStyleCarouselItem = {
  __typename?: 'CelebrityStyleCarouselItem';
  name: Scalars['String'];
  image: ImageV2;
  contentHtml: Scalars['String'];
  product: ProductListItem;
};

export type Checkout = {
  __typename?: 'Checkout';
  contentBlocks: Array<Checkout_ContentBlockText>;
};

export type Checkout_ContentBlockText = {
  __typename?: 'Checkout_ContentBlockText';
  id: Scalars['String'];
  contentHtml: Scalars['String'];
};

export enum ColourTheme {
  Default = 'DEFAULT',
  Dark = 'DARK',
  Light = 'LIGHT'
}

export type ConfigurableOption = {
  __typename?: 'ConfigurableOption';
  id: Scalars['Int'];
  optionLabel: Scalars['String'];
  valueLabel: Scalars['String'];
  valueId: Scalars['Int'];
};

export type ConfiguredVariant = {
  __typename?: 'ConfiguredVariant';
  uid: Scalars['String'];
  sku: Scalars['String'];
  color: Scalars['String'];
  colorValue: Scalars['String'];
  size: Scalars['String'];
  sizeValue: Scalars['String'];
};

export type ContentBlock = {
  id: Scalars['String'];
};

export enum ContentBlockAnimationCelebrityStyleCarousel {
  Movefade = 'MOVEFADE'
}

export enum ContentBlockAnimationMediaAndText50PercentSplit {
  Backgroundparallax = 'BACKGROUNDPARALLAX',
  Backgroundshrink = 'BACKGROUNDSHRINK',
  Movefade = 'MOVEFADE',
  Blockmovefade = 'BLOCKMOVEFADE'
}

export enum ContentBlockAnimationMediaBanner {
  Backgroundshrink = 'BACKGROUNDSHRINK',
  Movefade = 'MOVEFADE'
}

export enum ContentBlockAnimationMediaBasic {
  Backgroundparallax = 'BACKGROUNDPARALLAX',
  Backgroundshrink = 'BACKGROUNDSHRINK',
  Movefade = 'MOVEFADE',
  Blockmovefade = 'BLOCKMOVEFADE',
  Imagehoverexpand = 'IMAGEHOVEREXPAND'
}

export enum ContentBlockAnimationMediaCtAs {
  Backgroundparallax = 'BACKGROUNDPARALLAX',
  Backgroundshrink = 'BACKGROUNDSHRINK',
  Movefade = 'MOVEFADE'
}

export enum ContentBlockAnimationMediaCarousel {
  Movefade = 'MOVEFADE',
  Blockmovefade = 'BLOCKMOVEFADE'
}

export enum ContentBlockAnimationMediaCarouselSimple {
  Movefade = 'MOVEFADE'
}

export enum ContentBlockAnimationMediaCarouselWithMediaItem {
  Movefade = 'MOVEFADE',
  Blockmovefade = 'BLOCKMOVEFADE'
}

export enum ContentBlockAnimationMediaFocus {
  Backgroundparallax = 'BACKGROUNDPARALLAX',
  Backgroundshrink = 'BACKGROUNDSHRINK',
  Movefade = 'MOVEFADE',
  Blockmovefade = 'BLOCKMOVEFADE'
}

export enum ContentBlockAnimationMediaOverlap {
  Blockoverlap = 'BLOCKOVERLAP'
}

export enum ContentBlockAnimationMediaShopBySize {
  Backgroundshrink = 'BACKGROUNDSHRINK',
  Movefade = 'MOVEFADE',
  Blockmovefade = 'BLOCKMOVEFADE'
}

export enum ContentBlockAnimationProductCarousel {
  Movefade = 'MOVEFADE',
  Blockmovefade = 'BLOCKMOVEFADE'
}

export enum ContentBlockAnimationProductCarouselWithMediaItem {
  Movefade = 'MOVEFADE'
}

export enum ContentBlockAnimationText {
  Movefade = 'MOVEFADE'
}

export type ContentBlockCelebrityStyleCarousel = ContentBlock & {
  __typename?: 'ContentBlockCelebrityStyleCarousel';
  id: Scalars['String'];
  animation?: Maybe<ContentBlockAnimationCelebrityStyleCarousel>;
  marginDesktop: Margin;
  marginMobile: Margin;
  backgroundColour?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  seeMoreCallToAction: HtmlLink;
  itemCallToActionText: Scalars['String'];
  items: Array<CelebrityStyleCarouselItem>;
};

export type ContentBlockCharityScheme = ContentBlock & {
  __typename?: 'ContentBlockCharityScheme';
  id: Scalars['String'];
  animation?: Maybe<ContentBlockAnimationMediaBasic>;
  marginDesktop: Margin;
  marginMobile: Margin;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  callToAction?: Maybe<HtmlLink>;
  textAlignment?: Maybe<TextAlignment>;
  fontSize: FontSize;
  colourTheme: ColourTheme;
  backgroundColour?: Maybe<Scalars['String']>;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentCustomPositionDesktop: ContentCustomPosition;
  contentDefaultPositionMobile: MultipleDefaultContentPositions;
  contentCustomPositionMobile: ContentCustomPosition;
  mobileLayout: ContentBlockMobileLayoutMediaBasic;
  item: BlockMediaBasicItem;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  startAmount: Scalars['String'];
  endAmount: Scalars['String'];
};

export type ContentBlockLoyaltyScheme = ContentBlock & {
  __typename?: 'ContentBlockLoyaltyScheme';
  id: Scalars['String'];
  marginDesktop: Margin;
  marginMobile: Margin;
  title: Scalars['String'];
  subtitle: Scalars['String'];
  tiers: Array<LoyaltyTier>;
  currentTier: Scalars['Int'];
  currentPoints: Scalars['Int'];
  pendingPoints: Scalars['Int'];
  description: Scalars['String'];
};

export type ContentBlockMediaAndText50PercentSplit = ContentBlock & {
  __typename?: 'ContentBlockMediaAndText50PercentSplit';
  id: Scalars['String'];
  animation?: Maybe<ContentBlockAnimationMediaAndText50PercentSplit>;
  marginDesktop: Margin;
  marginMobile: Margin;
  textAlignment?: Maybe<TextAlignment>;
  fontSize: FontSize;
  colourTheme: ColourTheme;
  backgroundColour?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  callToActions?: Maybe<Array<HtmlLink>>;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentCustomPositionDesktop: ContentCustomPosition;
  contentDefaultPositionMobile: MultipleDefaultContentPositions;
  contentCustomPositionMobile: ContentCustomPosition;
  contentHtml?: Maybe<Scalars['String']>;
  /** @deprecated Please use campaign.products instead; expected deletion date: "01/02/2024" */
  products?: Maybe<Array<ProductListItem>>;
  campaign?: Maybe<ProductRecommendations>;
  accordion?: Maybe<Array<MediaAndText50PercentSplitAccordion>>;
  mediaItem: BlockMediaBasicItem;
  mediaLeftOnDesktop: Scalars['Boolean'];
  mediaTopOnMobile: Scalars['Boolean'];
};

export type ContentBlockMediaBanner = ContentBlock & {
  __typename?: 'ContentBlockMediaBanner';
  id: Scalars['String'];
  animation?: Maybe<ContentBlockAnimationMediaBanner>;
  title?: Maybe<Scalars['String']>;
  titleImage?: Maybe<ImageV2>;
  subtitle?: Maybe<Scalars['String']>;
  backgroundColour?: Maybe<Scalars['String']>;
  callToActions?: Maybe<Array<HtmlLink>>;
  colourTheme: ColourTheme;
  backgroundImage: ImageV2;
  fontSize: FontSize;
};

export type ContentBlockMediaBasic = ContentBlock & {
  __typename?: 'ContentBlockMediaBasic';
  id: Scalars['String'];
  animation?: Maybe<ContentBlockAnimationMediaBasic>;
  marginDesktop: Margin;
  marginMobile: Margin;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  callToAction?: Maybe<HtmlLink>;
  textAlignment?: Maybe<TextAlignment>;
  fontSize: FontSize;
  colourTheme: ColourTheme;
  backgroundColour?: Maybe<Scalars['String']>;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentCustomPositionDesktop: ContentCustomPosition;
  contentDefaultPositionMobile: MultipleDefaultContentPositions;
  contentCustomPositionMobile: ContentCustomPosition;
  mobileLayout: ContentBlockMobileLayoutMediaBasic;
  items: Array<BlockMediaBasicItem>;
};

export type ContentBlockMediaCtAs = ContentBlock & {
  __typename?: 'ContentBlockMediaCTAs';
  id: Scalars['String'];
  animation?: Maybe<ContentBlockAnimationMediaCtAs>;
  marginDesktop: Margin;
  marginMobile: Margin;
  textAlignment?: Maybe<TextAlignment>;
  fontSize: FontSize;
  colourTheme: ColourTheme;
  backgroundColour?: Maybe<Scalars['String']>;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentCustomPositionDesktop: ContentCustomPosition;
  contentDefaultPositionMobile: MultipleDefaultContentPositions;
  contentCustomPositionMobile: ContentCustomPosition;
  callToActionButtonBackgroundColourHex?: Maybe<Scalars['String']>;
  /** @deprecated Use `callToActionStyleDesktop`; expected deletion date: "01/02/2024" */
  callToActionStyle: CallToActionStyle;
  /** @deprecated Use `callToActionArrangementDesktop`; expected deletion date: "01/02/2024" */
  callToActionArrangement: CallToActionArrangement;
  callToActionStyleDesktop: CallToActionStyle;
  callToActionStyleMobile: CallToActionStyle;
  callToActionArrangementDesktop: CallToActionArrangement;
  callToActionArrangementMobile: CallToActionArrangement;
  callToActionIntro?: Maybe<Scalars['String']>;
  callToActions: Array<HtmlLink>;
  mediaItem?: Maybe<BlockMediaBasicItem>;
};

export type ContentBlockMediaCarousel = ContentBlock & {
  __typename?: 'ContentBlockMediaCarousel';
  id: Scalars['String'];
  animation?: Maybe<ContentBlockAnimationMediaCarousel>;
  marginDesktop: Margin;
  marginMobile: Margin;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  callToAction?: Maybe<HtmlLink>;
  textAlignment?: Maybe<TextAlignment>;
  fontSize: FontSize;
  colourTheme: ColourTheme;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentCustomPositionDesktop: ContentCustomPosition;
  contentDefaultPositionMobile: MultipleDefaultContentPositions;
  contentCustomPositionMobile: ContentCustomPosition;
  backgroundColour?: Maybe<Scalars['String']>;
  /** @deprecated Use `shouldScrollOnMobile` and `shouldScrollOnDesktop`; expected deletion date: "01/02/2024" */
  shouldScroll?: Maybe<Scalars['Boolean']>;
  shouldScrollOnMobile: Scalars['Boolean'];
  shouldScrollOnDesktop: Scalars['Boolean'];
  background?: Maybe<Media>;
  items: Array<BlockMediaCarouselItem>;
};

export type ContentBlockMediaCarouselSimple = ContentBlock & {
  __typename?: 'ContentBlockMediaCarouselSimple';
  id: Scalars['String'];
  animation?: Maybe<ContentBlockAnimationMediaCarouselSimple>;
  marginDesktop: Margin;
  marginMobile: Margin;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  callToAction?: Maybe<HtmlLink>;
  textAlignment?: Maybe<TextAlignment>;
  fontSize: FontSize;
  colourTheme: ColourTheme;
  backgroundColour?: Maybe<Scalars['String']>;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentCustomPositionDesktop: ContentCustomPosition;
  contentDefaultPositionMobile: MultipleDefaultContentPositions;
  contentCustomPositionMobile: ContentCustomPosition;
  shouldScrollOnMobile: Scalars['Boolean'];
  shouldScrollOnDesktop: Scalars['Boolean'];
  scrollStyleOnDesktop?: Maybe<CarouselScrollStyleOnDesktop>;
  arrowPositionDesktop: ArrowPosition;
  items: Array<BlockMediaCarouselItem>;
};

export type ContentBlockMediaCarouselSimpleMobileOnly = ContentBlock & {
  __typename?: 'ContentBlockMediaCarouselSimpleMobileOnly';
  id: Scalars['String'];
  backgroundColour?: Maybe<Scalars['String']>;
  marginMobile: Margin;
  textAlignment: TextAlignment;
  colourTheme: ColourTheme;
  items: Array<BlockMediaCarouselSimpleMobileOnlyItem>;
};

export type ContentBlockMediaCarouselWithMediaItem = ContentBlock & {
  __typename?: 'ContentBlockMediaCarouselWithMediaItem';
  id: Scalars['String'];
  animation?: Maybe<ContentBlockAnimationMediaCarouselWithMediaItem>;
  marginDesktop: Margin;
  marginMobile: Margin;
  mediaItemStyle: CarouselMediaItemStyle;
  mediaItem: BlockMediaCarouselItem;
  medias: Array<BlockMediaCarouselItem>;
  fontSize: FontSize;
  backgroundColour?: Maybe<Scalars['String']>;
  shouldScrollOnMobile: Scalars['Boolean'];
  shouldScrollOnDesktop: Scalars['Boolean'];
  scrollStyleOnDesktop?: Maybe<CarouselScrollStyleOnDesktop>;
  arrowPositionDesktop: ArrowPosition;
};

export type ContentBlockMediaDoubleHeightImageOnLeftSide = ContentBlock & {
  __typename?: 'ContentBlockMediaDoubleHeightImageOnLeftSide';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  animation?: Maybe<ContentBlockAnimationMediaBasic>;
  marginDesktop: Margin;
  marginMobile: Margin;
  textAlignment?: Maybe<TextAlignment>;
  fontSize: FontSize;
  colourTheme: ColourTheme;
  backgroundColour?: Maybe<Scalars['String']>;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentCustomPositionDesktop: ContentCustomPosition;
  contentDefaultPositionMobile: MultipleDefaultContentPositions;
  contentCustomPositionMobile: ContentCustomPosition;
  leftColumnWidth: Scalars['String'];
  items?: Maybe<Array<Maybe<BlockMediaBasicItem>>>;
};

export type ContentBlockMediaFocus = ContentBlock & {
  __typename?: 'ContentBlockMediaFocus';
  id: Scalars['String'];
  animation?: Maybe<ContentBlockAnimationMediaFocus>;
  marginDesktop: Margin;
  marginMobile: Margin;
  title: Scalars['String'];
  subtitle: Scalars['String'];
  callToAction: HtmlLink;
  imageLarge: ImageV2;
  imageCropped: ImageV2;
  textAlignment?: Maybe<TextAlignment>;
  fontSize: FontSize;
  backgroundColour?: Maybe<Scalars['String']>;
  colourTheme: ColourTheme;
  contentCustomPositionDesktop: ContentCustomPosition;
  contentCustomPositionMobile: ContentCustomPosition;
  mediaLeftOnDesktop: Scalars['Boolean'];
  mediaTopOnMobile: Scalars['Boolean'];
};

export type ContentBlockMediaOverlap = ContentBlock & {
  __typename?: 'ContentBlockMediaOverlap';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  animation?: Maybe<ContentBlockAnimationMediaOverlap>;
  marginDesktop: Margin;
  marginMobile: Margin;
  textAlignment?: Maybe<TextAlignment>;
  fontSize: FontSize;
  colourTheme: ColourTheme;
  backgroundColour?: Maybe<Scalars['String']>;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentCustomPositionDesktop: ContentCustomPosition;
  contentDefaultPositionMobile: MultipleDefaultContentPositions;
  contentCustomPositionMobile: ContentCustomPosition;
  itemOverlapTopGap: Scalars['Int'];
  items?: Maybe<Array<Maybe<BlockMediaBasicItem>>>;
};

export type ContentBlockMediaParallax = ContentBlock & {
  __typename?: 'ContentBlockMediaParallax';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  marginDesktop: Margin;
  marginMobile: Margin;
  textAlignment?: Maybe<TextAlignment>;
  fontSize: FontSize;
  colourTheme: ColourTheme;
  backgroundColour?: Maybe<Scalars['String']>;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentCustomPositionDesktop: ContentCustomPosition;
  contentDefaultPositionMobile: MultipleDefaultContentPositions;
  contentCustomPositionMobile: ContentCustomPosition;
  staticImageIndex: Scalars['Int'];
  items: Array<BlockMediaBasicItem>;
};

export type ContentBlockMediaShopBySize = ContentBlock & {
  __typename?: 'ContentBlockMediaShopBySize';
  id: Scalars['String'];
  animation?: Maybe<ContentBlockAnimationMediaShopBySize>;
  marginDesktop: Margin;
  marginMobile: Margin;
  fontSize: FontSize;
  mediaPromoTitle?: Maybe<MediaPromoTitle>;
  mediaItem?: Maybe<BlockMediaBasicItem>;
  sizesBackgroundColourHex?: Maybe<Scalars['String']>;
  sizesTextColourHex?: Maybe<Scalars['String']>;
  sizesLinkColourHex?: Maybe<Scalars['String']>;
  sizesItem: BlockShopBySizeItem;
  mediaItemOnLeft: Scalars['Boolean'];
};

export enum ContentBlockMobileLayoutMediaBasic {
  Stack = 'STACK',
  Scrollbar = 'SCROLLBAR'
}

export type ContentBlockProductCarousel = ContentBlock & {
  __typename?: 'ContentBlockProductCarousel';
  id: Scalars['String'];
  animation?: Maybe<ContentBlockAnimationProductCarousel>;
  marginDesktop: Margin;
  marginMobile: Margin;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  callToAction?: Maybe<HtmlLink>;
  textAlignment?: Maybe<TextAlignment>;
  fontSize: FontSize;
  colourTheme: ColourTheme;
  backgroundColour?: Maybe<Scalars['String']>;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentCustomPositionDesktop: ContentCustomPosition;
  contentDefaultPositionMobile: MultipleDefaultContentPositions;
  contentCustomPositionMobile: ContentCustomPosition;
  /** @deprecated Please use campaign.products instead; expected deletion date: "01/02/2024" */
  products: Array<ProductListItem>;
  campaign?: Maybe<ProductRecommendations>;
  shouldScrollOnMobile: Scalars['Boolean'];
  shouldScrollOnDesktop: Scalars['Boolean'];
  scrollStyleOnDesktop?: Maybe<CarouselScrollStyleOnDesktop>;
  arrowPositionDesktop: ArrowPosition;
  showBrandNames: Scalars['Boolean'];
  showPrices: Scalars['Boolean'];
  showProductNames: Scalars['Boolean'];
};

export type ContentBlockProductCarouselWithMediaItem = ContentBlock & {
  __typename?: 'ContentBlockProductCarouselWithMediaItem';
  id: Scalars['String'];
  animation?: Maybe<ContentBlockAnimationProductCarouselWithMediaItem>;
  marginDesktop: Margin;
  marginMobile: Margin;
  mediaItemStyle: CarouselMediaItemStyle;
  mediaItem: BlockMediaCarouselItem;
  /** @deprecated Please use campaign.products instead; expected deletion date: "01/02/2024" */
  products: Array<ProductListItem>;
  campaign?: Maybe<ProductRecommendations>;
  fontSize: FontSize;
  backgroundColour?: Maybe<Scalars['String']>;
  shouldScrollOnMobile: Scalars['Boolean'];
  shouldScrollOnDesktop: Scalars['Boolean'];
  scrollStyleOnDesktop?: Maybe<CarouselScrollStyleOnDesktop>;
  arrowPositionDesktop: ArrowPosition;
  showBrandNames: Scalars['Boolean'];
  showPrices: Scalars['Boolean'];
  showProductNames: Scalars['Boolean'];
};

export type ContentBlockRawHtml = ContentBlock & {
  __typename?: 'ContentBlockRawHtml';
  id: Scalars['String'];
  marginDesktop: Margin;
  marginMobile: Margin;
  rawHtml: Scalars['String'];
};

export type ContentBlockTabs = ContentBlock & {
  __typename?: 'ContentBlockTabs';
  id: Scalars['String'];
  marginDesktop: Margin;
  marginMobile: Margin;
  tabs: Array<ContentTab>;
};

export type ContentBlockText = ContentBlock & {
  __typename?: 'ContentBlockText';
  id: Scalars['String'];
  animation?: Maybe<ContentBlockAnimationText>;
  marginDesktop: Margin;
  marginMobile: Margin;
  textAlignment: TextAlignment;
  fontSize: FontSize;
  contentHtml: Scalars['String'];
  accordionTitle?: Maybe<Scalars['String']>;
  isAccordion?: Maybe<Scalars['Boolean']>;
  accordionOpenByDefault?: Maybe<Scalars['Boolean']>;
};

export type ContentCampaign = Campaign & {
  __typename?: 'ContentCampaign';
  id: Scalars['String'];
  sourceId: Scalars['String'];
  style: Scalars['String'];
  slogan: Scalars['String'];
  title: Scalars['String'];
  content: CampaignContent;
};

export type ContentCustomPosition = {
  __typename?: 'ContentCustomPosition';
  topPercent?: Maybe<Scalars['Int']>;
  leftPercent?: Maybe<Scalars['Int']>;
};

export type ContentTab = {
  __typename?: 'ContentTab';
  title: Scalars['String'];
  rawHtml: Scalars['String'];
};

export type CountriesCodeResponse = {
  __typename?: 'CountriesCodeResponse';
  countries: Array<CountryCode>;
};

export type CountryCode = {
  __typename?: 'CountryCode';
  code: Scalars['String'];
  name: Scalars['String'];
  availableRegions: Array<Region>;
};

export type CountryPickerDeliveryPrice = {
  __typename?: 'CountryPickerDeliveryPrice';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type CountryPickerItem = {
  __typename?: 'CountryPickerItem';
  code: Scalars['String'];
  name: Scalars['String'];
  instructions: Scalars['String'];
  deliveryPrices?: Maybe<Array<CountryPickerDeliveryPrice>>;
  moreInfoAction: HtmlLink;
};

export type CountryPickerList = {
  __typename?: 'CountryPickerList';
  instructions: Scalars['String'];
  searchLabel: Scalars['String'];
  items: Array<CountryPickerListItem>;
  cantFindAction: HtmlLink;
};

export type CountryPickerListItem = {
  __typename?: 'CountryPickerListItem';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CountryPickerResponse = CountryPickerList | CountryPickerItem;

export type CreateCustomerAddressInput = {
  /** An honorific, such as Dr, Mr, or Mrs for this specific address */
  prefix: Scalars['String'];
  /** The first name for this specific address */
  firstname: Scalars['String'];
  /** The last name for this specific address */
  lastname: Scalars['String'];
  /** The telephone number for this specific address */
  telephone: Scalars['String'];
  defaultBilling: Scalars['Boolean'];
  defaultShipping: Scalars['Boolean'];
  /** These address details can be plugged in via a third party lookup like loqate */
  countryCodeISO2: Scalars['String'];
  street: Array<Scalars['String']>;
  /** Postcode / Zipcode */
  postcode: Scalars['String'];
  city: Scalars['String'];
  /** This is required for US, List of Region Ids can be grabbed via separate middleware query */
  region?: Maybe<AddressRegionInput>;
};

export type CreateCustomerInput = {
  /** An honorific, such as Dr, Mr, or Mrs */
  prefix: Scalars['String'];
  /** The customer's first name */
  firstname: Scalars['String'];
  /** The customer's last name */
  lastname: Scalars['String'];
  /** The customer's email address */
  email: Scalars['EmailAddress'];
  /** The customer's password */
  password: Scalars['Password'];
  /** The customer's birthday in the format DD-MM */
  birthday?: Maybe<Scalars['Birthday']>;
  /** Indicates whether the customer would like to receive marketing emails */
  isSubscribed: Scalars['Boolean'];
};

export type Cta = {
  __typename?: 'Cta';
  ctaText: Scalars['String'];
  url: Scalars['String'];
  openInNewTab: Scalars['Boolean'];
  /** @deprecated please use shouldFollow instead; expected deletion date: "01/02/2025" */
  noFollow: Scalars['Boolean'];
  shouldFollow: Scalars['Boolean'];
};

export type Currency = {
  __typename?: 'Currency';
  code: Scalars['String'];
  symbol: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  /** An honorific, such as Dr, Mr, or Mrs */
  prefix?: Maybe<Scalars['String']>;
  /** The customer's first name */
  firstname?: Maybe<Scalars['String']>;
  /** The customer's last name */
  lastname?: Maybe<Scalars['String']>;
  /** The customer's email address */
  email?: Maybe<Scalars['EmailAddress']>;
  /** The customer's birthday in the format DD-MM */
  birthday?: Maybe<Scalars['String']>;
  /** Indicates whether the customer would like to receive marketing emails */
  isSubscribed?: Maybe<Scalars['Boolean']>;
  /** Timestamp indicating when the customer was created. */
  createdAt?: Maybe<Scalars['String']>;
  /** The customer's current loyalty tier */
  currentTier?: Maybe<Scalars['Int']>;
  /** The customer's current loyalty points */
  currentPoints?: Maybe<Scalars['Int']>;
  /** The customer's pending loyalty points */
  pendingPoints?: Maybe<Scalars['Int']>;
  /** An array containing the customer's shipping and billing addresses. */
  addresses?: Maybe<Array<CustomerAddress>>;
};


export type CustomerAddressesArgs = {
  filter?: Maybe<AddressFilter>;
};

export type CustomerAddress = {
  __typename?: 'CustomerAddress';
  /** A unique identifier for this specific address */
  id: Scalars['Int'];
  /** An honorific, such as Dr, Mr, or Mrs for this specific address */
  prefix: Scalars['String'];
  /** The first name for this specific address */
  firstname: Scalars['String'];
  /** The last name for this specific address */
  lastname: Scalars['String'];
  /** The telephone number for this specific address */
  telephone: Scalars['String'];
  defaultBilling: Scalars['Boolean'];
  defaultShipping: Scalars['Boolean'];
  /** 2 digit ISO country code, e.g GB, US etc */
  countryCodeISO2: Scalars['String'];
  street: Array<Maybe<Scalars['String']>>;
  /** Postcode / Zipcode */
  postcode: Scalars['String'];
  city: Scalars['String'];
  region?: Maybe<CustomerAddressRegion>;
};

export type CustomerAddressRegion = {
  __typename?: 'CustomerAddressRegion';
  regionId: Scalars['Int'];
  regionCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type CustomerData = {
  __typename?: 'CustomerData';
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  city: Scalars['String'];
  postcode: Scalars['String'];
  countryCode: Scalars['String'];
  emailNotHashed: Scalars['String'];
};

export type CustomerOutput = {
  __typename?: 'CustomerOutput';
  customer: Customer;
};

export type CustomerUpdateInput = {
  prefix?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Birthday']>;
  isSubscribed?: Maybe<Scalars['Boolean']>;
};

export type CustomerUpdateResponse = {
  __typename?: 'CustomerUpdateResponse';
  prefix?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Birthday']>;
  isSubscribed?: Maybe<Scalars['Boolean']>;
};

export type CustomizableOptionInput = {
  superAttribute?: Maybe<Array<SuperAttribute>>;
};


export type DeliveryDates = {
  __typename?: 'DeliveryDates';
  from: Scalars['String'];
  to: Scalars['String'];
};

export type DeliveryOption = {
  __typename?: 'DeliveryOption';
  id: Scalars['String'];
  title: Scalars['String'];
  price: Scalars['String'];
  terms: Scalars['String'];
};

export type DesktopMultiColumnSubMenu = SubMenu & {
  __typename?: 'DesktopMultiColumnSubMenu';
  menuId?: Maybe<Scalars['String']>;
  link?: Maybe<HtmlLink>;
  columns: Array<SubMenuColumn>;
};

export type DesktopSingleColumnSubMenu = SubMenu & {
  __typename?: 'DesktopSingleColumnSubMenu';
  menuId?: Maybe<Scalars['String']>;
  link?: Maybe<HtmlLink>;
  column: SubMenuSingleColumn;
};

export type DesktopSubMenu = DesktopMultiColumnSubMenu | DesktopSingleColumnSubMenu | IndexSubMenu;

export enum DeviceType {
  Desktop = 'DESKTOP',
  Tablet = 'TABLET',
  Mobile = 'MOBILE'
}

export type Discount = {
  __typename?: 'Discount';
  amount: Money;
  label: Scalars['String'];
};


export type EntrancePopup = {
  __typename?: 'EntrancePopup';
  description?: Maybe<Scalars['String']>;
  signUpButton?: Maybe<Scalars['String']>;
  signUpSuccess?: Maybe<Scalars['String']>;
  organicSearch?: Maybe<EntrancePopupOrganicSearch>;
};

export type EntrancePopupOrganicSearch = {
  __typename?: 'EntrancePopupOrganicSearch';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ExitPopupFormInput = {
  emailAddress: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  previewStyle?: Maybe<Scalars['String']>;
};

export type ExtensionAttributesCollectionPoint = {
  __typename?: 'ExtensionAttributesCollectionPoint';
  address: Scalars['String'];
  telephoneNumber: Scalars['String'];
  distanceUnit: Scalars['String'];
  distanceValue: Scalars['Float'];
  distanceFormatted: Scalars['String'];
  postcode: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  latitude: Scalars['String'];
  longitude: Scalars['String'];
  times?: Maybe<Array<Scalars['String']>>;
  city: Scalars['String'];
  street1: Scalars['String'];
  street2: Scalars['String'];
};

export type FilterEqualTypeInput = {
  eq?: Maybe<Scalars['NonEmptyString']>;
  in?: Maybe<Array<Scalars['NonEmptyString']>>;
};

export type FilterRangeTypeInput = {
  from?: Maybe<Scalars['NonEmptyString']>;
  to?: Maybe<Scalars['NonEmptyString']>;
};

export type FixedProductTax = {
  __typename?: 'FixedProductTax';
  amount?: Maybe<Money>;
  label?: Maybe<Scalars['String']>;
};

export type FlashSalePromo = {
  __typename?: 'FlashSalePromo';
  id: Scalars['String'];
  promoTitle: Scalars['String'];
  promoDescription: Scalars['String'];
  image: ImageV2;
  callToAction: HtmlLink;
  expiryDate: Scalars['String'];
  termsAndConditions: Scalars['String'];
};

export enum FontSize {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE'
}

export type Footer = {
  __typename?: 'Footer';
  menu: Array<SubMenuLinkList>;
  newsletterSignup: NewsletterSignup;
  socialMedia: SocialMedia;
  appStores: AppStores;
  cookiePolicy: Scalars['String'];
  copyright?: Maybe<Scalars['String']>;
};

export type GenderCode = {
  __typename?: 'GenderCode';
  code: Scalars['String'];
  label: Scalars['String'];
};

export type GiftBoxProduct = {
  __typename?: 'GiftBoxProduct';
  line: Scalars['String'];
  sku: Scalars['String'];
  brand: Scalars['String'];
  name: Scalars['String'];
  giftBox?: Maybe<Scalars['String']>;
  wasPrice?: Maybe<Scalars['Float']>;
  price: Scalars['Float'];
  formattedPrice: Scalars['String'];
  isSalable: Scalars['Boolean'];
  isWasPriceShown: Scalars['Boolean'];
  urlKey: Scalars['String'];
};

export type GiftCardBalance = {
  __typename?: 'GiftCardBalance';
  cardNumber: Scalars['String'];
  balance: Scalars['String'];
  formattedBalance: Scalars['String'];
};

export type GiftCardBalanceResult = GiftCardBalance | GiftCardNotFound;

export type GiftCardNotFound = {
  __typename?: 'GiftCardNotFound';
  message: Scalars['String'];
};


export type Header = {
  __typename?: 'Header';
  logo: Logo;
  promoBanner?: Maybe<PromoBanner>;
};

export enum HeaderColourTheme {
  Dark = 'DARK',
  Light = 'LIGHT'
}

export type HeaderConsolidated = {
  __typename?: 'HeaderConsolidated';
  header?: Maybe<Header>;
  countryPicker?: Maybe<CountryPickerResponse>;
  headerNavigation?: Maybe<HeaderNavigation>;
  headerTrendingSearch?: Maybe<HeaderTrendingSearch>;
  footer?: Maybe<Footer>;
  brandIndex: BrandIndex;
};

export type HeaderNavigation = {
  __typename?: 'HeaderNavigation';
  desktopMenu: Array<DesktopSubMenu>;
  mobileMenu: Array<MobileSubMenu>;
  mobileUtilityLinks?: Maybe<Array<MobileUtilityLink>>;
};

export type HeaderTrendingMenu = {
  __typename?: 'HeaderTrendingMenu';
  menuId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  links?: Maybe<Array<HtmlLink>>;
};

export type HeaderTrendingProducts = {
  __typename?: 'HeaderTrendingProducts';
  fredhopperCampaignId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  products?: Maybe<Array<ProductListItem>>;
};

export type HeaderTrendingSearch = {
  __typename?: 'HeaderTrendingSearch';
  trendingProducts?: Maybe<Array<Maybe<HeaderTrendingProducts>>>;
  trendingMenu?: Maybe<Array<Maybe<HeaderTrendingMenu>>>;
};

export type Hreflang = {
  __typename?: 'Hreflang';
  locale: Scalars['String'];
  url: Scalars['String'];
};

export type HtmlLink = {
  __typename?: 'HtmlLink';
  text: Scalars['String'];
  url: Scalars['String'];
  newWindow: Scalars['Boolean'];
  linkId?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  alt?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type ImageCampaign = Campaign & {
  __typename?: 'ImageCampaign';
  id: Scalars['String'];
  sourceId: Scalars['String'];
  style: Scalars['String'];
  slogan: Scalars['String'];
  title: Scalars['String'];
  image: ImageV2;
};

export type ImageHtmlLink = {
  __typename?: 'ImageHtmlLink';
  image: ImageV2;
  subtitle?: Maybe<Scalars['String']>;
  link?: Maybe<HtmlLink>;
  openByDefaultOnMobile?: Maybe<Scalars['Boolean']>;
};

export type ImageLink = {
  __typename?: 'ImageLink';
  image: ImgDetails;
  url: Scalars['String'];
  openInNewTab: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
};

export type ImageLinkCampaign = Campaign & {
  __typename?: 'ImageLinkCampaign';
  id: Scalars['String'];
  sourceId: Scalars['String'];
  style: Scalars['String'];
  slogan: Scalars['String'];
  title: Scalars['String'];
  imageLinks: Array<ImageLink>;
};

export type ImageV2 = {
  __typename?: 'ImageV2';
  /** @deprecated Please use desktop instead; expected deletion date: "01/02/2024" */
  desktopSrc: Scalars['String'];
  desktop: ImgDetails;
  /** @deprecated Please use tablet instead; expected deletion date: "01/02/2024" */
  tabletSrc?: Maybe<Scalars['String']>;
  tablet?: Maybe<ImgDetails>;
  /** @deprecated Please use mobile instead; expected deletion date: "01/02/2024" */
  mobileSrc: Scalars['String'];
  mobile: ImgDetails;
  alt?: Maybe<Scalars['String']>;
};

export type ImgDetails = {
  __typename?: 'ImgDetails';
  src: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type IndexSubMenu = SubMenu & {
  __typename?: 'IndexSubMenu';
  menuId?: Maybe<Scalars['String']>;
  link?: Maybe<HtmlLink>;
  index: SubMenuIndex;
  links: SubMenuLinkList;
  images?: Maybe<Array<Maybe<ImageHtmlLink>>>;
};


export type Job = {
  __typename?: 'Job';
  /** The job identifier */
  id: Scalars['ID'];
  /** The job title */
  title: Scalars['String'];
  /** The job shortcode */
  shortcode: Scalars['String'];
  /** The country name as defined in job editor */
  country: Scalars['String'];
  /** The region as defined in job editor */
  region: Scalars['String'];
  /** The city as defined in job editor */
  city: Scalars['String'];
  /** The job department as defined in editor */
  department: Scalars['String'];
  /** The job sub department as defined in editor */
  subDepartment?: Maybe<Scalars['String']>;
  /** A collection with the ancestor departments of the job department including that */
  departments: Array<Scalars['String']>;
  /** URL to the application form */
  url: Scalars['String'];
};

export type JobListing = {
  __typename?: 'JobListing';
  label: Scalars['String'];
  jobs: Array<Job>;
};

export enum JobListingType {
  Headoffice = 'HEADOFFICE',
  Retail = 'RETAIL'
}



export type Link = {
  __typename?: 'Link';
  url: Scalars['String'];
  text: Scalars['String'];
};

export type LinkList = {
  __typename?: 'LinkList';
  title: Scalars['String'];
  links: Array<Cta>;
};

export type ListItem = ProductListItem | AdvertListItem;

export type Logo = {
  __typename?: 'Logo';
  dark: ImageV2;
  light: ImageV2;
};


export type LoyaltyTier = {
  __typename?: 'LoyaltyTier';
  id: Scalars['Int'];
  name: Scalars['String'];
  minPoints: Scalars['Int'];
  maxPoints: Scalars['Int'];
  barLabel: Scalars['String'];
};

export type Margin = {
  __typename?: 'Margin';
  topPixels?: Maybe<Scalars['Int']>;
  rightPixels?: Maybe<Scalars['Int']>;
  bottomPixels?: Maybe<Scalars['Int']>;
  leftPixels?: Maybe<Scalars['Int']>;
};

export type Media = ImageV2 | Video;

export type MediaAndText50PercentSplitAccordion = {
  __typename?: 'MediaAndText50PercentSplitAccordion';
  title: Scalars['String'];
  contentHtml: Scalars['String'];
  accordionOpenByDefault: Scalars['Boolean'];
};

export type MediaPromoTitle = {
  __typename?: 'MediaPromoTitle';
  title: Scalars['String'];
  colourHex: Scalars['String'];
};

export type Metadata = {
  __typename?: 'Metadata';
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  robots?: Maybe<Scalars['String']>;
  hreflangs: Array<Hreflang>;
};

export type Minibag = {
  __typename?: 'Minibag';
  title: Scalars['String'];
  deliveryOptions: Array<Scalars['String']>;
  /** @deprecated Please use Cart; expected deletion date: "01/10/2024" */
  bag: Bag;
  content: Scalars['String'];
  bagButton: Cta;
  checkoutButton: Cta;
  amazonPayButton?: Maybe<AmazonPayButton>;
};

export type MobileMultiItemSubMenu = SubMenu & {
  __typename?: 'MobileMultiItemSubMenu';
  menuId?: Maybe<Scalars['String']>;
  link?: Maybe<HtmlLink>;
  items: Array<SubMenuItem>;
  images?: Maybe<Array<Maybe<ImageHtmlLink>>>;
};

export type MobileSubMenu = MobileMultiItemSubMenu | IndexSubMenu;

export type MobileUtilityLink = {
  __typename?: 'MobileUtilityLink';
  link?: Maybe<HtmlLink>;
  iconId?: Maybe<Scalars['String']>;
};

export type Money = {
  __typename?: 'Money';
  currency?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  formattedValue?: Maybe<Scalars['String']>;
};

export enum MultipleDefaultContentPositions {
  Above = 'ABOVE',
  Below = 'BELOW',
  Inside = 'INSIDE'
}

export type Mutation = {
  __typename?: 'Mutation';
  addProductToCart: AddProductToCartResponse;
  applyCouponToCart: ApplyCouponToCartResponse;
  removeCouponFromCart: RemoveCouponFromCartResponse;
  assignForterTokenToCart: AssignForterTokenToCartResponse;
  removeItemFromCart: RemoveItemFromCartResponse;
  updateCartItems: UpdateCartItemsResponse;
  applyGiftCardToCart: ApplyGiftCardToCartResponse;
  removeGiftCardFromCart: RemoveGiftCardFromCartResponse;
  setDeliveryOptionTypeToCart: SetDeliveryOptionTypeToCartResponse;
  setShippingAddressesOnCart: SetShippingAddressesOnCartResponse;
  setShippingMethodsOnCart: SetShippingMethodsOnCartResponse;
  setBillingAddressOnCart: SetBillingAddressOnCartResponse;
  /** Apply a payment method to the cart. */
  setPaymentMethodOnCart: SetPaymentMethodOnCartResponse;
  /** Converts the cart to an order. */
  placeOrder?: Maybe<PlaceOrderOutput>;
  /** Create customer account */
  createCustomer?: Maybe<CustomerOutput>;
  /** Customer login */
  customerLogin: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  requestPasswordResetEmail: Scalars['Boolean'];
  updateCustomer: CustomerUpdateResponse;
  createCustomerAddress?: Maybe<CustomerAddress>;
  updateCustomerAddress?: Maybe<CustomerAddress>;
  addProductToBag: AddProductToBagResponse;
  welcomePopupFormSubmission: WelcomeProgrammeResponse;
  competitionFormSubmission: WelcomeProgrammeResponse;
  exitPopupFormSubmission: WelcomeProgrammeResponse;
  newsletterFormSubmission: WelcomeProgrammeResponse;
};


export type MutationAddProductToCartArgs = {
  input: AddProductToCartInput;
};


export type MutationApplyCouponToCartArgs = {
  input: ApplyCouponToCartInput;
};


export type MutationAssignForterTokenToCartArgs = {
  input: AssignForterTokenToCartInput;
};


export type MutationRemoveItemFromCartArgs = {
  input: RemoveItemFromCartInput;
};


export type MutationUpdateCartItemsArgs = {
  input: UpdateCartItemsInput;
};


export type MutationApplyGiftCardToCartArgs = {
  input: ApplyGiftCardToCartsInput;
};


export type MutationRemoveGiftCardFromCartArgs = {
  input: RemoveGiftCardFromCartsInput;
};


export type MutationSetDeliveryOptionTypeToCartArgs = {
  input: SetDeliveryOptionTypeToCartInput;
};


export type MutationSetShippingAddressesOnCartArgs = {
  input: SetShippingAddressesOnCartInput;
};


export type MutationSetShippingMethodsOnCartArgs = {
  input: SetShippingMethodsOnCartInput;
};


export type MutationSetBillingAddressOnCartArgs = {
  input: SetBillingAddressOnCartInput;
};


export type MutationSetPaymentMethodOnCartArgs = {
  input: SetPaymentMethodOnCartInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationCustomerLoginArgs = {
  email: Scalars['EmailAddress'];
  password: Scalars['Password'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['EmailAddress'];
  resetPasswordToken: Scalars['String'];
  newPassword: Scalars['Password'];
};


export type MutationRequestPasswordResetEmailArgs = {
  email: Scalars['EmailAddress'];
};


export type MutationUpdateCustomerArgs = {
  input: CustomerUpdateInput;
};


export type MutationCreateCustomerAddressArgs = {
  input: CreateCustomerAddressInput;
};


export type MutationUpdateCustomerAddressArgs = {
  id: Scalars['Int'];
  input: UpdateCustomerAddressInput;
};


export type MutationAddProductToBagArgs = {
  input: AddProductToCartInput;
};


export type MutationWelcomePopupFormSubmissionArgs = {
  input: WelcomeProgrammeFormInput;
};


export type MutationCompetitionFormSubmissionArgs = {
  input: WelcomeProgrammeFormInput;
  source: Scalars['String'];
};


export type MutationExitPopupFormSubmissionArgs = {
  input: ExitPopupFormInput;
};


export type MutationNewsletterFormSubmissionArgs = {
  input: NewsletterFormInput;
};

export type NearbyStore = StoreInterface & {
  __typename?: 'NearbyStore';
  name: Scalars['String'];
  type: Scalars['String'];
  grade?: Maybe<Scalars['String']>;
  streetLine1?: Maybe<Scalars['String']>;
  streetLine2?: Maybe<Scalars['String']>;
  streetLine3?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  geoAddress: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  collectLabel?: Maybe<Scalars['String']>;
  openingHours: Array<StoreOpeningHour>;
  isCollectEnabled?: Maybe<Scalars['Boolean']>;
  distance: Scalars['Float'];
  distanceUnits: Scalars['String'];
};

export type NewsletterFormInput = {
  emailAddress: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  telephone?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  previewStyle?: Maybe<Scalars['String']>;
};

export type NewsletterSignup = {
  __typename?: 'NewsletterSignup';
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  formTitle: Scalars['String'];
  emailPlaceholder: Scalars['String'];
  genderCodes: Array<GenderCode>;
  legal: Scalars['String'];
  thanks: NewsletterThanks;
  /** @deprecated use Popups.; expected deletion date: "01/02/2024" */
  welcomePopup?: Maybe<WelcomePopup>;
  popups?: Maybe<Popups>;
};

export type NewsletterThanks = {
  __typename?: 'NewsletterThanks';
  messagePrimary: Scalars['String'];
  messageSecondary: Scalars['String'];
  offer: Scalars['String'];
};


export type Order = {
  __typename?: 'Order';
  number: Scalars['String'];
};

export type OrderTrackingError = {
  __typename?: 'OrderTrackingError';
  /** @deprecated Please check for errors in the response object; expected deletion date: "01/10/2024" */
  status: Scalars['String'];
  /** @deprecated Please check for errors in the response object; expected deletion date: "01/10/2024" */
  statusText: Scalars['String'];
  /** @deprecated Please check for errors in the response object; expected deletion date: "01/10/2024" */
  message: Scalars['String'];
};

export type OrderTrackingInformation = {
  __typename?: 'OrderTrackingInformation';
  orderNumber: Scalars['String'];
  trackingUrl?: Maybe<Scalars['String']>;
};

export type OrderTrackingResponse = OrderTrackingInformation | OrderTrackingError;

export type OutOfStockMenu = {
  __typename?: 'OutOfStockMenu';
  title: Scalars['String'];
  firstColumn: Array<HtmlLink>;
  secondColumn?: Maybe<Array<HtmlLink>>;
};


export type PaymentMethodInput = {
  /** Required input for Adyen payment method. */
  adyen?: Maybe<AdyenInput>;
  /** The internal name for the payment method. */
  code: Scalars['String'];
};

export type PlaceOrderOutput = {
  __typename?: 'PlaceOrderOutput';
  order?: Maybe<Order>;
};

export type Popups = {
  __typename?: 'Popups';
  welcome?: Maybe<WelcomePopup>;
  entrance?: Maybe<EntrancePopup>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['String'];
  name: Scalars['String'];
  urlKey: Scalars['String'];
  url: Scalars['String'];
  lineNumber: Scalars['LineNumber'];
  style: Scalars['String'];
  brand: Scalars['String'];
  brandCode: Scalars['String'];
  gender: Scalars['String'];
  productGenderCode: Scalars['ProductGenderCode'];
  colour: Scalars['String'];
  material?: Maybe<Scalars['String']>;
  imageRoundel?: Maybe<Scalars['String']>;
  shortDescription: Scalars['String'];
  description: Scalars['String'];
  upper?: Maybe<Scalars['String']>;
  lining?: Maybe<Scalars['String']>;
  sole?: Maybe<Scalars['String']>;
  heelHeight?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  /** @deprecated Please use height instead; expected deletion date: "01/02/2024" */
  bagHeight?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  /** @deprecated Please use width instead; expected deletion date: "01/02/2024" */
  bagWidth?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['String']>;
  /** @deprecated Please use depth instead; expected deletion date: "01/02/2024" */
  bagDepth?: Maybe<Scalars['String']>;
  newIn: Scalars['Boolean'];
  sale: Scalars['Boolean'];
  offer: Scalars['Boolean'];
  exclusive: Scalars['Boolean'];
  clearance: Scalars['Boolean'];
  priceDrop: Scalars['Boolean'];
  price: ProductPrice;
  breadcrumbs: Array<ProductBreadcrumb>;
  sizes: Array<ProductSize>;
  recommendations: Array<ProductRecommendations>;
  colours: Array<Product>;
  cmsPage: CmsProductPage;
  amazonPayButton?: Maybe<AmazonPayButton>;
  media: ProductMedia;
  sizeChart?: Maybe<SizeConversionChart>;
};

export type ProductBreadcrumb = {
  __typename?: 'ProductBreadcrumb';
  name: Scalars['String'];
  urlKey: Scalars['String'];
  url: Scalars['String'];
  level: Scalars['Int'];
};

export type ProductCampaign = Campaign & {
  __typename?: 'ProductCampaign';
  id: Scalars['String'];
  sourceId: Scalars['String'];
  style: Scalars['String'];
  slogan: Scalars['String'];
  title: Scalars['String'];
  products: Array<ProductListItem>;
};


export type ProductImage = {
  __typename?: 'ProductImage';
  largeSrc?: Maybe<Scalars['String']>;
  mediumSrc?: Maybe<Scalars['String']>;
  smallSrc?: Maybe<Scalars['String']>;
  tinySrc?: Maybe<Scalars['String']>;
  alt?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
};

export type ProductImageInfo = {
  __typename?: 'ProductImageInfo';
  url: Scalars['String'];
  shotType: Scalars['String'];
  alt?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
};


export type ProductListFilterInput = {
  colour_group?: Maybe<FilterEqualTypeInput>;
  size?: Maybe<FilterEqualTypeInput>;
  brand?: Maybe<FilterEqualTypeInput>;
  gender?: Maybe<FilterEqualTypeInput>;
  price?: Maybe<FilterRangeTypeInput>;
  material?: Maybe<FilterEqualTypeInput>;
  top_category?: Maybe<FilterEqualTypeInput>;
  newIn?: Maybe<FilterEqualTypeInput>;
  offer?: Maybe<FilterEqualTypeInput>;
  sale?: Maybe<FilterEqualTypeInput>;
  heel_height_description?: Maybe<FilterEqualTypeInput>;
  discount_price?: Maybe<FilterRangeTypeInput>;
};

export type ProductListItem = {
  __typename?: 'ProductListItem';
  name: Scalars['String'];
  lineNumber: Scalars['LineNumber'];
  url: Scalars['String'];
  urlKey: Scalars['String'];
  brand: Scalars['String'];
  brandCode?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  productGenderCode?: Maybe<Scalars['ProductGenderCode']>;
  colour?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  media: ProductMedia;
  price: ProductPrice;
  newIn: Scalars['Boolean'];
  sale: Scalars['Boolean'];
  offer: Scalars['Boolean'];
  exclusive: Scalars['Boolean'];
  clearance: Scalars['Boolean'];
  priceDrop: Scalars['Boolean'];
  imageRoundel?: Maybe<Scalars['String']>;
  /** @deprecated Please use imageRoundel instead; expected deletion date: "01/02/2024" */
  roundel?: Maybe<Scalars['String']>;
};


export type ProductMedia = {
  __typename?: 'ProductMedia';
  productImages: Array<ProductImageInfo>;
  productRotation: Array<ProductImageInfo>;
  /** @deprecated Please use product_images instead; expected deletion date: "01/02/2024" */
  images: Array<ProductImage>;
  /** @deprecated Please use product_rotation instead; expected deletion date: "01/02/2024" */
  rotation: Array<ProductImage>;
};

export type ProductPrice = {
  __typename?: 'ProductPrice';
  now: Scalars['String'];
  nowFormatted: Scalars['String'];
  was?: Maybe<Scalars['String']>;
  wasFormatted?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['String']>;
  discountFormatted?: Maybe<Scalars['String']>;
  discountPercentage?: Maybe<Scalars['String']>;
};

export type ProductRecommendations = {
  __typename?: 'ProductRecommendations';
  id: Scalars['String'];
  title: Scalars['String'];
  products: Array<ProductListItem>;
};

export type ProductRoute = Route & {
  __typename?: 'ProductRoute';
  requestUrl: Scalars['URL'];
  /** Url Key used to identify the product. */
  productUrlKey: Scalars['String'];
  lineNumber: Scalars['LineNumber'];
};

export type ProductSearch = {
  __typename?: 'ProductSearch';
  sourceId?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  returnedSearch?: Maybe<Scalars['String']>;
  redirectLocation?: Maybe<Scalars['String']>;
  items: Array<ListItem>;
  pagination?: Maybe<CategoryPagination>;
  filters: Array<CategoryFilter>;
  sortBy: Array<CategorySortByOption>;
  activeFilters: Array<CategoryActiveFilter>;
  recommendations: Array<ProductRecommendations>;
  shouldIndex: Scalars['Boolean'];
};

export type ProductSearchKeywordSuggestion = {
  __typename?: 'ProductSearchKeywordSuggestion';
  query: Scalars['String'];
  totalItems: Scalars['Int'];
};

export type ProductSearchProductSuggestion = {
  __typename?: 'ProductSearchProductSuggestion';
  name: Scalars['String'];
  lineNumber: Scalars['LineNumber'];
  url: Scalars['String'];
  brand: Scalars['String'];
  thumbnail: Scalars['String'];
  price: ProductPrice;
};

export type ProductSearchSuggestions = {
  __typename?: 'ProductSearchSuggestions';
  query: Scalars['String'];
  keywords: Array<ProductSearchKeywordSuggestion>;
  products: Array<ProductSearchProductSuggestion>;
};

export type ProductSize = {
  __typename?: 'ProductSize';
  sku: Scalars['String'];
  stockMessage?: Maybe<Scalars['String']>;
  inStock: Scalars['Boolean'];
  fit: Array<Scalars['String']>;
  label: Scalars['String'];
  /** @deprecated Please use label instead; expected deletion date: "01/02/2025" */
  labels: SizeLabels;
  /** Gift Box sizing, e.g. Small, Medium, Large */
  giftBox?: Maybe<Scalars['String']>;
  /** Gift Box product sku */
  giftBoxCode?: Maybe<Scalars['String']>;
};

export type PromoBanner = {
  __typename?: 'PromoBanner';
  /** @deprecated Use `desktopHeightPixels`; expected deletion date: "01/02/2024" */
  heightPixels?: Maybe<Scalars['Int']>;
  desktopHeightPixels?: Maybe<Scalars['Int']>;
  mobileHeightPixels?: Maybe<Scalars['Int']>;
  hasSeparator?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use `desktopItems`; expected deletion date: "01/02/2024" */
  items: Array<PromoBannerItem>;
  desktopItems: Array<PromoBannerItem>;
  mobileItems: Array<PromoBannerItem>;
  continuityBar?: Maybe<Scalars['String']>;
};

export type PromoBannerItem = {
  __typename?: 'PromoBannerItem';
  backgroundImage?: Maybe<ImageV2>;
  backgroundColourHex?: Maybe<Scalars['String']>;
  textAlignment?: Maybe<TextAlignment>;
  fontSize?: Maybe<FontSize>;
  fontColourHex?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['String']>;
  textHtml?: Maybe<Scalars['String']>;
  textLink?: Maybe<HtmlLink>;
  linkWrapsWholeItem?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use `linkWrapsWholeItem`; expected deletion date: "01/02/2024" */
  url?: Maybe<Scalars['String']>;
  /** @deprecated Use the value from textLink; expected deletion date: "01/02/2024" */
  openInNewTab?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  addressLookupBySearch: AddressLookupBySearchResponse;
  addressLookupRetrieval: AddressLookupRetrievalResponse;
  addressLookupByGeocode: AddressLookupByGeocodeResponse;
  adyenPaymentMethods?: Maybe<AdyenPaymentMethods>;
  cart: Cart;
  category?: Maybe<Category>;
  campaign?: Maybe<Campaign>;
  checkout: Checkout;
  availableShippingCountries: CountriesCodeResponse;
  currency?: Maybe<Currency>;
  deliveryMethodsForCart: Array<CartShippingMethod>;
  /** @deprecated use headerConsolidated to reduce CMS calls.; expected deletion date: "01/02/2024" */
  footer: Footer;
  getGiftBoxProducts: Array<GiftBoxProduct>;
  giftCardBalance: GiftCardBalanceResult;
  isEmailAvailable: Scalars['Boolean'];
  jobListings: Array<JobListing>;
  /** @deprecated Please use customer instead; expected deletion date: "01/02/2025" */
  customerData?: Maybe<CustomerData>;
  customer?: Maybe<Customer>;
  metadata?: Maybe<Metadata>;
  minibag: Minibag;
  nearbyStores: Array<NearbyStore>;
  storeStockAvailability: Array<NearbyStore>;
  orderTracking: OrderTrackingResponse;
  paymentMethods: Array<Image>;
  product?: Maybe<Product>;
  productsByStyle: Array<Product>;
  productRecommendations: Array<ProductRecommendations>;
  productMedia: ProductMedia;
  products: Array<ProductListItem>;
  productSearch?: Maybe<ProductSearch>;
  productSearchSuggestions?: Maybe<ProductSearchSuggestions>;
  productSizeAvailability: Array<ProductSize>;
  route?: Maybe<Route>;
  siteSwitcher?: Maybe<SiteSwitcher>;
  sizeChart?: Maybe<SizeConversionChart>;
  storeList?: Maybe<StoreList>;
  /** @deprecated Use launchdarkly toggles instead; expected deletion date: "01/02/2024" */
  showWelcomeProgrammePopup: WelcomeProgrammeResponse;
  CMSPageBuilder?: Maybe<CmsPage>;
  /** @deprecated use headerConsolidated to reduce CMS calls.; expected deletion date: "01/02/2024" */
  countryPicker: CountryPickerResponse;
  headerConsolidated: HeaderConsolidated;
  sitemap?: Maybe<Sitemap>;
};


export type QueryAddressLookupBySearchArgs = {
  text: Scalars['String'];
  countryCode: Scalars['String'];
  container?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryAddressLookupRetrievalArgs = {
  id: Scalars['String'];
};


export type QueryAddressLookupByGeocodeArgs = {
  latitude: Scalars['Latitude'];
  longitude: Scalars['Longitude'];
};


export type QueryAdyenPaymentMethodsArgs = {
  shopperLocale?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};


export type QueryCategoryArgs = {
  categoryPath: Scalars['CategoryPath'];
  filters?: Maybe<ProductListFilterInput>;
  currentPage?: Maybe<Scalars['ProductListCurrentPage']>;
  perPage?: Maybe<Scalars['ProductListPageSize']>;
  sortItemsBy?: Maybe<Scalars['NonEmptyString']>;
  deviceType?: Maybe<DeviceType>;
};


export type QueryCampaignArgs = {
  slogan: Scalars['String'];
};


export type QueryGiftCardBalanceArgs = {
  cardNumber: Scalars['GiftCardNumber'];
};


export type QueryIsEmailAvailableArgs = {
  email: Scalars['EmailAddress'];
};


export type QueryJobListingsArgs = {
  type?: Maybe<JobListingType>;
};


export type QueryMetadataArgs = {
  categoryPath?: Maybe<Scalars['CategoryPath']>;
  productUrlKey?: Maybe<Scalars['String']>;
};


export type QueryNearbyStoresArgs = {
  latitude: Scalars['String'];
  longitude: Scalars['String'];
};


export type QueryStoreStockAvailabilityArgs = {
  upc: Scalars['String'];
  address: Scalars['String'];
};


export type QueryOrderTrackingArgs = {
  orderNumber: Scalars['String'];
  postcode: Scalars['String'];
};


export type QueryProductArgs = {
  urlKey: Scalars['String'];
};


export type QueryProductsByStyleArgs = {
  style: Scalars['String'];
  urlKey?: Maybe<Scalars['String']>;
};


export type QueryProductRecommendationsArgs = {
  lineNumber: Scalars['LineNumber'];
};


export type QueryProductMediaArgs = {
  lineNumber: Scalars['LineNumber'];
  urlKey?: Maybe<Scalars['String']>;
};


export type QueryProductsArgs = {
  lineNumbers: Array<Scalars['LineNumber']>;
};


export type QueryProductSearchArgs = {
  query: Scalars['NonEmptyString'];
  filters?: Maybe<ProductListFilterInput>;
  currentPage?: Maybe<Scalars['ProductListCurrentPage']>;
  perPage?: Maybe<Scalars['ProductListPageSize']>;
  sortItemsBy?: Maybe<Scalars['NonEmptyString']>;
  deviceType?: Maybe<DeviceType>;
};


export type QueryProductSearchSuggestionsArgs = {
  query: Scalars['String'];
};


export type QueryProductSizeAvailabilityArgs = {
  lineNumber: Scalars['LineNumber'];
};


export type QueryRouteArgs = {
  requestUrl: Scalars['URL'];
};


export type QuerySiteSwitcherArgs = {
  domain: Scalars['String'];
};


export type QuerySizeChartArgs = {
  brandCode: Scalars['String'];
  productGenderCode: Scalars['ProductGenderCode'];
};


export type QueryShowWelcomeProgrammePopupArgs = {
  pageType: WelcomeProgrammePageType;
};


export type QueryCmsPageBuilderArgs = {
  ID: Scalars['String'];
  currentPageNumber?: Maybe<Scalars['Int']>;
};


export type QueryCountryPickerArgs = {
  CountryCode?: Maybe<Scalars['String']>;
};


export type QueryHeaderConsolidatedArgs = {
  CountryCode?: Maybe<Scalars['String']>;
};

export type RecentStories = {
  __typename?: 'RecentStories';
  items: Array<RecentStory>;
  allStoriesCTA?: Maybe<HtmlLink>;
  shareTitle: Scalars['String'];
  shareAccounts: Array<ThirdPartyAccount>;
};

export type RecentStory = {
  __typename?: 'RecentStory';
  image: ImageV2;
  media?: Maybe<Media>;
  storyType: Scalars['String'];
  title: Scalars['String'];
  storyCTA: HtmlLink;
};

export type RedirectRoute = Route & {
  __typename?: 'RedirectRoute';
  requestUrl: Scalars['URL'];
  /** Redirection status codes indicates the nature of the redirect and whether it is temporary or permanent. */
  redirectStatusCode: Scalars['String'];
  redirectUrl: Scalars['URL'];
};

export type Region = {
  __typename?: 'Region';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type RemoveCouponFromCartResponse = {
  __typename?: 'RemoveCouponFromCartResponse';
  cart?: Maybe<Cart>;
};

export type RemoveGiftCardFromCartResponse = {
  __typename?: 'RemoveGiftCardFromCartResponse';
  cart?: Maybe<Cart>;
};

export type RemoveGiftCardFromCartsInput = {
  giftCardCode: Scalars['String'];
};

export type RemoveItemFromCartInput = {
  cartItemUid: Scalars['String'];
};

export type RemoveItemFromCartResponse = {
  __typename?: 'RemoveItemFromCartResponse';
  cart?: Maybe<Cart>;
};

export type Route = {
  requestUrl: Scalars['URL'];
};

export type SelectedShippingMethod = {
  __typename?: 'SelectedShippingMethod';
  carrierCode: Scalars['String'];
  carrierTitle: Scalars['String'];
  methodCode: Scalars['String'];
  methodTitle: Scalars['String'];
  amount: Money;
  baseAmount?: Maybe<Money>;
  priceExclTax: Money;
  priceInclTax: Money;
};

export type SetBillingAddressOnCartInput = {
  billingAddress: BillingAddressInput;
};

export type SetBillingAddressOnCartResponse = {
  __typename?: 'SetBillingAddressOnCartResponse';
  cart: Cart;
};

export type SetDeliveryOptionTypeToCartInput = {
  selectedOption: CartShippingMethodOptions;
};

export type SetDeliveryOptionTypeToCartResponse = {
  __typename?: 'SetDeliveryOptionTypeToCartResponse';
  status: Scalars['Boolean'];
  message: Scalars['String'];
};

export type SetPaymentMethodOnCartInput = {
  /** The payment method data to apply to the cart. */
  paymentMethod: PaymentMethodInput;
};

export type SetPaymentMethodOnCartResponse = {
  __typename?: 'SetPaymentMethodOnCartResponse';
  cart?: Maybe<Cart>;
};

export type SetShippingAddressesOnCartInput = {
  shippingAddresses: Array<ShippingAddressInput>;
};

export type SetShippingAddressesOnCartResponse = {
  __typename?: 'SetShippingAddressesOnCartResponse';
  cart?: Maybe<Cart>;
};

export type SetShippingMethodsOnCartInput = {
  shippingMethods: Array<ShippingMethodInput>;
};

export type SetShippingMethodsOnCartResponse = {
  __typename?: 'SetShippingMethodsOnCartResponse';
  cart?: Maybe<Cart>;
};

export type ShippingAddressInput = {
  address?: Maybe<CartAddressInput>;
  customerAddressId?: Maybe<Scalars['Int']>;
};

export type ShippingCartAddress = {
  __typename?: 'ShippingCartAddress';
  availableShippingMethods?: Maybe<Array<Maybe<AvailableShippingMethods>>>;
  selectedShippingMethod?: Maybe<SelectedShippingMethod>;
};

export type ShippingMethodExtensionAttributes = {
  __typename?: 'ShippingMethodExtensionAttributes';
  deliveryDateFrom?: Maybe<Scalars['String']>;
  deliveryDateTo?: Maybe<Scalars['String']>;
  deliveryDates?: Maybe<Array<Maybe<DeliveryDates>>>;
  cutoff?: Maybe<Scalars['String']>;
  googlemapMarkerUrl?: Maybe<Scalars['String']>;
  googlemapMarkerActiveUrl?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  collectionPoint?: Maybe<ExtensionAttributesCollectionPoint>;
};

export type ShippingMethodInput = {
  carrierCode: Scalars['String'];
  methodCode: Scalars['String'];
  extShippingInfo?: Maybe<Scalars['String']>;
  shippingCarrierType: Scalars['String'];
};

export type SiteSwitcher = {
  __typename?: 'SiteSwitcher';
  country: Scalars['String'];
  domain: Scalars['String'];
  title: Scalars['String'];
  redirect: Cta;
};

export type Sitemap = {
  __typename?: 'Sitemap';
  title: Scalars['String'];
  subtitle: Scalars['String'];
  metadata?: Maybe<Metadata>;
  categoryLinks?: Maybe<Array<Maybe<CategoryLinks>>>;
};

export type SizeConversion = {
  __typename?: 'SizeConversion';
  code: Scalars['String'];
  fitOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  labels: SizeLabels;
  dimensions?: Maybe<SizeDimensions>;
  recommendedAge?: Maybe<Scalars['String']>;
};

export type SizeConversionChart = {
  __typename?: 'SizeConversionChart';
  brandCode: Scalars['String'];
  productGenderCode: Scalars['ProductGenderCode'];
  sizes: Array<SizeConversion>;
};

export type SizeCta = {
  __typename?: 'SizeCta';
  ctaText: Scalars['String'];
  url: Scalars['String'];
  openInNewTab: Scalars['Boolean'];
  /** @deprecated please use shouldFollow instead; expected deletion date: "01/02/2025" */
  noFollow: Scalars['Boolean'];
  shouldFollow: Scalars['Boolean'];
  selected: Scalars['Boolean'];
};

export type SizeDimensions = {
  __typename?: 'SizeDimensions';
  in?: Maybe<Scalars['Float']>;
  cm?: Maybe<Scalars['Float']>;
};

export type SizeLabels = {
  __typename?: 'SizeLabels';
  original: Scalars['String'];
  eu?: Maybe<Scalars['String']>;
  uk?: Maybe<Scalars['String']>;
  us?: Maybe<Scalars['String']>;
};

export type SizeLinkList = {
  __typename?: 'SizeLinkList';
  title: Scalars['String'];
  links: Array<SizeCta>;
};

export type SizeOptions = {
  __typename?: 'SizeOptions';
  optionId: Scalars['Int'];
  optionLabel: Scalars['String'];
  attributeId: Scalars['Int'];
  attributeCode: Scalars['String'];
};

export type SocialMedia = {
  __typename?: 'SocialMedia';
  title: Scalars['String'];
  accounts: Array<ThirdPartyAccount>;
};

export type Store = StoreInterface & {
  __typename?: 'Store';
  name: Scalars['String'];
  type: Scalars['String'];
  grade?: Maybe<Scalars['String']>;
  streetLine1?: Maybe<Scalars['String']>;
  streetLine2?: Maybe<Scalars['String']>;
  streetLine3?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  geoAddress: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  collectLabel?: Maybe<Scalars['String']>;
  openingHours: Array<StoreOpeningHour>;
  isCollectEnabled: Scalars['Boolean'];
};

export type StoreGroup = {
  __typename?: 'StoreGroup';
  label: Scalars['String'];
  stores: Array<Store>;
};

export type StoreInterface = {
  name: Scalars['String'];
  type: Scalars['String'];
  grade?: Maybe<Scalars['String']>;
  streetLine1?: Maybe<Scalars['String']>;
  streetLine2?: Maybe<Scalars['String']>;
  streetLine3?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  geoAddress: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  collectLabel?: Maybe<Scalars['String']>;
  openingHours: Array<StoreOpeningHour>;
};

export type StoreList = {
  __typename?: 'StoreList';
  kurtgeiger: Array<StoreGroup>;
  concessions: Array<StoreGroup>;
  international: Array<StoreGroup>;
};

export type StoreOpeningHour = {
  __typename?: 'StoreOpeningHour';
  day: Scalars['String'];
  openingTime?: Maybe<Scalars['String']>;
  closingTime?: Maybe<Scalars['String']>;
  isClosed: Scalars['Boolean'];
};

export type StoriesGeneralListItem = {
  __typename?: 'StoriesGeneralListItem';
  title: Scalars['String'];
  subtitle: Scalars['String'];
  textColour: Scalars['String'];
  callToAction: HtmlLink;
  /** @deprecated Use `media` instead which supports both image and video; expected deletion date: "01/02/2024" */
  image?: Maybe<ImageV2>;
  media?: Maybe<Media>;
};

export type SubMenu = {
  menuId?: Maybe<Scalars['String']>;
  link?: Maybe<HtmlLink>;
};

export type SubMenuColumn = {
  __typename?: 'SubMenuColumn';
  menuId?: Maybe<Scalars['String']>;
  items: Array<SubMenuItem>;
};

export type SubMenuImageLinkList = {
  __typename?: 'SubMenuImageLinkList';
  menuId?: Maybe<Scalars['String']>;
  links: Array<ImageHtmlLink>;
  openByDefaultOnMobile?: Maybe<Scalars['Boolean']>;
};

export type SubMenuIndex = {
  __typename?: 'SubMenuIndex';
  title: Scalars['String'];
  allUrl: Scalars['String'];
  /** @deprecated Use field on BrandIndex query - and only call it once per page; expected deletion date: "01/02/2024" */
  index: Array<SubMenuIndexLink>;
};

export type SubMenuIndexLink = {
  __typename?: 'SubMenuIndexLink';
  letter: Scalars['String'];
  url: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type SubMenuItem = SubMenuLinkList | SubMenuImageLinkList | ImageHtmlLink | SubMenuShopBySize;

export type SubMenuLinkList = {
  __typename?: 'SubMenuLinkList';
  menuId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  links: Array<HtmlLink>;
  openByDefaultOnMobile?: Maybe<Scalars['Boolean']>;
};

export type SubMenuShopBySize = {
  __typename?: 'SubMenuShopBySize';
  title: Scalars['String'];
  sizes: Array<HtmlLink>;
  openByDefaultOnMobile?: Maybe<Scalars['Boolean']>;
};

export type SubMenuSingleColumn = {
  __typename?: 'SubMenuSingleColumn';
  items: Array<SubMenuItem>;
};

export type SuperAttribute = {
  attributeId: Scalars['String'];
  optionId: Scalars['String'];
};

export enum TextAlignment {
  Left = 'LEFT',
  Centre = 'CENTRE',
  Right = 'RIGHT'
}

export type ThirdPartyAccount = {
  __typename?: 'ThirdPartyAccount';
  platform: Scalars['String'];
  url: Scalars['String'];
};


export type UnknownRoute = Route & {
  __typename?: 'UnknownRoute';
  /** We don't know what this route is or how to handle it. */
  requestUrl: Scalars['URL'];
};

export type UpdateCartItemsInput = {
  cartItems: Array<CartItemUpdateInput>;
};

export type UpdateCartItemsResponse = {
  __typename?: 'UpdateCartItemsResponse';
  cart?: Maybe<Cart>;
};

export type UpdateCustomerAddressInput = {
  /** An honorific, such as Dr, Mr, or Mrs for this specific address */
  prefix?: Maybe<Scalars['String']>;
  /** The first name for this specific address */
  firstname?: Maybe<Scalars['String']>;
  /** The last name for this specific address */
  lastname?: Maybe<Scalars['String']>;
  /** The telephone number for this specific address */
  telephone?: Maybe<Scalars['String']>;
  defaultBilling?: Maybe<Scalars['Boolean']>;
  defaultShipping?: Maybe<Scalars['Boolean']>;
  /** These address details can be plugged in via a third party lookup like loqate */
  countryCodeISO2?: Maybe<Scalars['String']>;
  street?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Postcode / Zipcode */
  postcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<AddressRegionInput>;
};

export type VariableShipping = {
  __typename?: 'VariableShipping';
  countryCode: Scalars['String'];
  sectionLabel: Scalars['String'];
  firstLineMessage: Scalars['String'];
  secondLineMessage?: Maybe<Scalars['String']>;
  isAccordion: Scalars['Boolean'];
};

export type Video = {
  __typename?: 'Video';
  /** @deprecated Use `videoIdDesktop`; expected deletion date: "01/02/2024" */
  videoId: Scalars['String'];
  videoIdDesktop: Scalars['String'];
  videoThumbnailUrlDesktop?: Maybe<Scalars['String']>;
  videoIdMobile: Scalars['String'];
  videoThumbnailUrlMobile?: Maybe<Scalars['String']>;
};

export type WelcomePopup = {
  __typename?: 'WelcomePopup';
  linkTrigger: Scalars['String'];
  image: ImageV2;
  thanks: Scalars['String'];
};

export type WelcomeProgrammeErrorResponse = {
  __typename?: 'WelcomeProgrammeErrorResponse';
  status: Scalars['Int'];
  statusText: Scalars['String'];
  message: Scalars['String'];
  /** @deprecated Please use message instead; expected deletion date: "01/02/2024" */
  reason: Scalars['String'];
  /** @deprecated Please use message instead; expected deletion date: "01/02/2024" */
  detailedMessage: Scalars['String'];
};

export type WelcomeProgrammeFormInput = {
  emailAddress: Scalars['String'];
  optIn?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  previewStyle?: Maybe<Scalars['String']>;
};

export enum WelcomeProgrammePageType {
  Static = 'STATIC',
  Plp = 'PLP',
  Pdp = 'PDP'
}

export type WelcomeProgrammeResponse = WelcomeProgrammeSuccessResponse | WelcomeProgrammeErrorResponse;

export type WelcomeProgrammeSuccessResponse = {
  __typename?: 'WelcomeProgrammeSuccessResponse';
  success: Scalars['Boolean'];
};
