import styled from 'styled-components';

import { GlobalAnimationStyleObject } from '../../../../sites/animations';
import { StyledNextImageWrapper } from '../../../basic/NextImage/NextImageStyles';
import { MediaFontSize } from '../../../styles/fontSize';
import {
  ColourTheme,
  ContentBlockAnimationMediaBanner,
  FontSize,
  Maybe,
} from '../../../types/middleware-types';
import { StyledAccordionTrigger, StyledTitleDropdown } from '../../Accordion/AccordionStyles';

interface StyledMediaBanner {
  $backgroundColour: Maybe<string>;
}

export const StyledMediaBanner = styled.div<StyledMediaBanner>`
  position: relative;
  min-height: 25rem;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${({ $backgroundColour }) => $backgroundColour};

  @media ${({ theme }) => theme.devices.large} {
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    height: 40rem;
  }
`;

interface StyledMediaBannerContainerProps {
  $animate: boolean;
  $animationType?: Maybe<ContentBlockAnimationMediaBanner>;
  $animationStyle: GlobalAnimationStyleObject;
  $moduleIndex: number;
}

export const StyledMediaBannerContainer = styled.div<StyledMediaBannerContainerProps>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${({ $animationType, theme }) =>
  $animationType === 'MOVEFADE'
    ? `
        transform: translateY(-100%);
        background: ${theme.vars.white};`
    : ''};
  ${({ $animate, $animationType, $animationStyle }) => {
      if ($animate) {
        if ($animationType === 'MOVEFADE') {
          return $animationStyle?.scrollDown;
        }
      }
      return null;
    }}

  img {
    ${({ $animationType }) => ($animationType === 'MOVEFADE' ? 'opacity: 0' : '')};
    ${({ $animate, $animationType, $animationStyle }) => {
      if ($animate) {
        if ($animationType === 'MOVEFADE') {
          return $animationStyle.fadeIn;
        }
      }
      return null;
    }}
  }
`;

interface StyledBackgroundImageProps {
  $animate: boolean;
  $animationType?: Maybe<ContentBlockAnimationMediaBanner>;
  $animationStyle: GlobalAnimationStyleObject;
  $moduleIndex: number;
}

export const StyledBackgroundImage = styled.div<StyledBackgroundImageProps>`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;

  img,
  source {
    object-fit: cover;
    height: unset;
    width: 100%;
    ${({ $animate, $animationType, $animationStyle, $moduleIndex }) =>
  $animate && $animationType === 'BACKGROUNDSHRINK' && $moduleIndex === 0 && $animationStyle.zoomIn}

    @media ${({ theme }) => theme.devices.large} {
      height: 100%;
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    position: static;
  }

  ${StyledNextImageWrapper} {
    height: 100%;

    span {
      height: 100% !important;
    }
  }
`;

interface StyledBannerContentProps {
  $colourTheme?: ColourTheme;
  $headerHeight: number;
  $fontMediaSize: FontSize;
  $sahNewHeader?: boolean;
}

export const StyledBannerContent = styled.div<StyledBannerContentProps>`
  color: ${({ theme, $colourTheme }) => {
  if ($colourTheme === 'LIGHT') {
    return theme.blockTextColors.light;
  }
  if ($colourTheme === 'DARK') {
    return theme.blockTextColors.dark;
  }
  return theme.blockTextColors.default;
}};
  width: 90%;
  margin: ${({ $headerHeight }) => `${$headerHeight + 36}px auto 0`};
  display: flex;
  flex-direction: column;
  z-index: 1;

  @media ${({ theme }) => theme.devices.large} {
    position: absolute;
    top: ${({ $headerHeight }) => `${$headerHeight + 40}px`};
    left: 3rem;
    max-width: 50%;
    margin: 0 auto;
  }

  img {
    max-width: 32rem;
    height: auto;
    object-fit: contain;
  }

  p {
    ${({ theme }) => theme.types.labelSmallStyle};
    text-align: center;

    @media ${({ theme }) => theme.devices.large} {
      text-align: left;
    }
  }

  h2,
  p {
    color: inherit;
  }
  ${StyledTitleDropdown} {
    display: block;
    width: 100%;

    p {
      margin-bottom: 0;
    }
    ${StyledAccordionTrigger} {
      color: inherit;
      ${({ theme }) => theme.types.h2Style};
    }

    @media ${({ theme }) => theme.devices.large} {
      display: none;
    }
  }
  ${MediaFontSize};

  @media ${({ theme }) => theme.devices.smallOnly} {
    ${({ $sahNewHeader }) =>
  $sahNewHeader ? `
      margin-top: 190px;
      margin-bottom: 20px;
    ` : ''};
  }

  @media ${({ theme }) => theme.devices.mediumOnly} {
    ${({ $sahNewHeader }) =>
    $sahNewHeader ? `
        margin-top: 190px;
        margin-bottom: 20px;
      ` : ''};
  }
`;

interface StyledCtaListProps {
  $ctaNumber?: number;
  $sahNewHeader?: boolean;
}

export const StyledCtaList = styled.ul<StyledCtaListProps>`
  list-style: none;
  display: ${({ $sahNewHeader }) => $sahNewHeader ? 'none' : 'flex'};

  @media ${({ theme }) => theme.devices.large} {
    display: flex;
  }

  padding: 0;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;

  li {
    margin: 0 ${({ $ctaNumber = 0 }) => ($ctaNumber > 3 ? 1 : 2)}rem;
    white-space: nowrap;

    &:first-of-type {
      margin-left: 0;
    }

    a {
      ${({ theme }) => theme.types.tertiaryLinkStyles};
      color: inherit;
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    margin-top: ${({ theme }) => theme.vars.sizeM};
    justify-content: unset;
    width: 100%;
  }
`;

export const StyledContentInner = styled.div`
  display: none;

  @media ${({ theme }) => theme.devices.large} {
    display: block;
  }
`;
