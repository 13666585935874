'use client'

import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { updateMediaBannerColourTheme } from '../../../features/nav';
import { updatePlpLoading } from '../../../features/plpContent';
import { selectIsCompactHeader, selectPageName, selectToggles } from '../../../features/static';
import {
  ColourTheme,
  ContentBlockAnimationMediaBanner,
  ContentBlockMediaBanner,
  HtmlLink,
  Maybe,
} from '../../../types/middleware-types';
import useAnimationOnScroll from '../../../utils/customHooks/useAnimationOnScroll';
import useMobileWatcher from '../../../utils/customHooks/useMobileWatcher';
import parseCMSMediaProp from '../../../utils/formatters/parseCMSMediaProp';
import MediaBannerStructure from './MediaBannerStructure';

export interface MediaBannerProps extends ContentBlockMediaBanner {
  index?: number;
  animationMediaBanner?: Maybe<ContentBlockAnimationMediaBanner>;
  bannerCtas?: Maybe<Array<HtmlLink>>;
  reload?: boolean;
  mobileCtasLimit?: number;
}

const MediaBanner: FC<MediaBannerProps> = ({
  backgroundImage,
  subtitle,
  title,
  bannerCtas,
  titleImage,
  colourTheme = ColourTheme.Default,
  fontSize,
  animationMediaBanner: animationType,
  reload,
  index: moduleIndex = 0,
  mobileCtasLimit = 0,
  backgroundColour = 'transparent',
}) => {
  const compactHeader = useSelector(selectIsCompactHeader);
  const dispatch = useDispatch();
  const toggles = useSelector(selectToggles);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const { isMobile } = useMobileWatcher(['mobile'], theme.vars);
  const pageName = useSelector(selectPageName);

  const forceCompactHeader = toggles?.engs13031UpdateShoeaholicsHeaderAndMenu ? true : compactHeader;
  const sahNewHeader = toggles?.engs13031UpdateShoeaholicsHeaderAndMenu;
  const useReactRoutingLinks = toggles?.engs14345ReactRoutingATagsToLinks;

  const headerHeightMobile = forceCompactHeader ? 43 : 89;
  const headerHeightDesktop = forceCompactHeader ? 123 : 111;

  // use colour theme from media banner for header to match on PLP pages
  useEffect(() => {
    dispatch(updateMediaBannerColourTheme(colourTheme === 'LIGHT' ? 'light' : 'dark'));
  }, [colourTheme]);

  const { enteredView, animationStyle, forwardedRef } = useAnimationOnScroll(animationType);
  
  // banner links reloading the page. 
  useEffect(() => {
    dispatch(
      updatePlpLoading({
        loading,
      }),
    );
  }, [loading]);

  const componentProps = {
    backgroundImage: parseCMSMediaProp(backgroundImage),
    subtitle,
    title,
    bannerCtas:
      mobileCtasLimit > 0 &&
      isMobile &&
      bannerCtas &&
      bannerCtas?.length > mobileCtasLimit
        ? bannerCtas.slice(0, mobileCtasLimit)
        : bannerCtas,
    titleImage: parseCMSMediaProp(titleImage),
    pageName,
    colourTheme,
    forwardedRef,
    animationType,
    animate: enteredView,
    animationStyle,
    moduleIndex,
    fontSize,
    headerHeight: isMobile ? headerHeightMobile : headerHeightDesktop,
    reload,
    backgroundColour,
    toggles,
    titleDropdownTitle: titleImage ? '' : title,
    setLoading,
    sahNewHeader,
    useReactRoutingLinks,
  };

  return <MediaBannerStructure {...componentProps} />;
};

export default MediaBanner;
